// import web3 from "web3";
// import React, { useEffect, useRef } from "react";
// import { useWeb3 } from "./useWeb3";
import config from '../lib/config'
import { useContract,useContractApprove} from "./useContract";
import {useWeb3} from "../hooks/useWeb3"


export const useCommonContractFunctions = async (Method, Data, Wallet_Details,gasFeeValue) => {

    console.log("fdsvfvsdfvsdfsdfvsdf",Method, Data, Wallet_Details,gasFeeValue);
    var Hash = null
    var Transcation = null
    var Contract =null
    Contract = await useContract() 
    var web3 = await useWeb3()
    console.log("Contract",Contract);
    var encoded = await Contract.methods[Method](...Data);

    console.log("encoded",encoded);
    console.log("gasFeeValue",gasFeeValue,gasFeeValue!=="");


if(gasFeeValue!==""&&Method!=="acceptBId")
{     Transcation = {
    from: Wallet_Details.UserAccountAddr,
    to:config.multipleContract,
    data: encoded,
    value: gasFeeValue
}}
else{
     Transcation = { from: Wallet_Details.UserAccountAddr, }
}
console.log("Transcation",Transcation);
var gasPrice = await web3.eth.getGasPrice();
console.log("gasPrice",gasPrice);

var gasLimit = await encoded.estimateGas(Transcation);
console.log("gasLimit",gasLimit);

Transcation["gas"] = gasLimit;
    
    try {
        await Contract.methods[Method](...Data).send({ from: Wallet_Details.UserAccountAddr,gasLimit:gasLimit,gasPrice:gasPrice }).on('transactionHash', async (transactionHash) => {
            console.log("transactionHash",transactionHash);
            Hash = transactionHash
        })
        return Hash

    }
    catch (error) {
        console.log("Error On Common Contract Functions", error)
        return false
    }


}

export const useCommonContractFunctionsApprove = async (Method, Data, Wallet_Details,gasFeeValue,DETH_ABI, NewTokenAddress) => {

    console.log("fdsvfvsdfvsdfvsdfvsdf",Method, Data, Wallet_Details,gasFeeValue);
    var Hash = null
    var Transcation = null
    var Contract =null
    var web3 = await useWeb3()
    Contract = await useContractApprove(DETH_ABI, NewTokenAddress)
    

   console.log("Contract",Contract);
            var encoded = await Contract.methods["approve"](...Data).encodeABI();

            console.log("encoded",encoded);
            console.log("gasFeeValue",gasFeeValue,gasFeeValue!=="");


        if(gasFeeValue!=="")
    {     Transcation = {
            from: Wallet_Details.UserAccountAddr,
            to:config.multipleContract,
            data: encoded,
            value: gasFeeValue
        }}
        else{
             Transcation = { from: Wallet_Details.UserAccountAddr, }
        }
        console.log("Transcation",Transcation);
        var gasPrice = await web3.eth.getGasPrice();
        console.log("gasPrice",gasPrice);

        var gasLimit = await web3.eth.estimateGas(Transcation);
        console.log("gasLimit",gasLimit);

        Transcation["gas"] = gasLimit;
    
    try {
        await Contract.methods[Method](...Data).send({ from: Wallet_Details.UserAccountAddr,gasLimit:gasLimit,gasPrice:gasPrice }).on('transactionHash', async (transactionHash) => {
            console.log("transactionHash",transactionHash);
            Hash = transactionHash
        })
        return Hash

    }
    catch (error) {
        console.log("Error On Common Contract Functions", error)
        return false
    }


}


// export const useCommonContractFunctionsCall = async (Method, Data, Wallet_Details) => {

//     console.log("fdsvfvsdfvsdfvsdfvsdf",Method, Data, Wallet_Details);
//     var Hash = null
//     var Type = config.Type
//     var Contract = await useContract()



// }