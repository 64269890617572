import React, { useState, useEffect, useRef } from "react";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link, useHistory, NavLink } from "react-router-dom";
import Polygon from "../../assets/images/polygon.svg";
import Metamaskicon from "../../assets/images/metamask.svg";
import wallet_05 from "../../assets/images/wallet_05.png";
import Metamask from "../../assets/images/MetaMask_Fox.png";
import Walletconnect from "../../assets/images/WalletConnect-Logo.png";
import $ from "jquery";
import { useSelector, useDispatch } from "react-redux";
import {
  Account_Connect,
  Account_disConnect,
} from "../../actions/redux/action";
import isEmpty from "../../lib/isEmpty";
import config from "../../lib/config";
import {
  convertionValue,
  WenlamboConvert,
  getListOfToken,
} from "../../actions/v1/token";
import Web3 from "web3";
import DETH_ABI from "../../ABI/DETH_ABI.json";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import {
  AddressUserDetails_GetOrSave_Action,
  getprofile,
} from "../../actions/v1/user";
import CopyToClipboard from "react-copy-to-clipboard";
import trade from "../../ABI/Trade.json";
import { EthereumProvider } from "@walletconnect/ethereum-provider";
import axios from "axios";
import unblokLogo from "../../assets/images/Header/updated-site-logo.svg";
import walletDividerPopup from "../../assets/images/Header/walletpopupdivider.svg";
import {connectWallet} from "../../hooks/useWallet"
toast.configure();
let toasterOption = config.toasterOption;
export default function Head(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const history = useHistory();
  const [show, setShow] = useState(false);
  const [searchshow, setSearchshow] = useState(true);
  const [mobsearch, setMobsearch] = useState(false);
  const [Deciveconn, setDeciveconn] = useState(false);

  if (show == true) {
    $("body").addClass("overflowbody");
  } else {
    $("body").removeClass("overflowbody");
  }
  const Wallet_Details = useSelector((state) => state.wallet_connect_context);
  const dispatch = useDispatch();
  const { ...rest } = props;
  const timerRef = useRef(null);

  useEffect(() => {
    window.addEventListener("load", async (event) => {
      if (
        localStorage.getItem("walletConnectType") == "mt" ||
        localStorage.getItem("walletConnectType") == "BinanceWallet"
      ) {
        if (window.ethereum) {
          window.ethereum.on(
            "accountsChanged",
            function (accounts) {
              if (timerRef.current) {
                toast.success("Account Changed");

                clearTimeout(timerRef.current);
                window.location.reload();
              }
              timerRef.current = setTimeout(() => {
                initialConnectWallet("mt");
              }, 1000);
            },
            [Wallet_Details.UserAccountAddr]
          );

          window.ethereum.on("chainChanged", async function (networkId) {
            if (networkId == config.chainId) {
              if (timerRef.current) {
                clearTimeout(timerRef.current);
              }

              timerRef.current = setTimeout(() => {
                toast.success("Network Changed");
                window.location.reload();
              }, 1000);
            } else {
              dispatch({
                type: Account_disConnect,
              });
            }
          });
        }
      } else if (localStorage.walletConnectType == "wc") {
        var provider3 = null;
        if (provider3 == null) {
          provider3 = await initialConnectWallet("wc");
        } else if (provider3 != null) {
          provider3.on("connect", () => {
            initialConnectWallet("wc");
          });
          provider3.on("disconnect", () => {
            localStorage.removeItem("walletConnectType");
          });
        }
      }
    });
  }, []);

  async function getBuyTokensList() {
    var TokenList = await getListOfToken();
    if (
      TokenList &&
      TokenList.data &&
      TokenList.data.data &&
      TokenList.data.data.data
    ) {
      var get_token_details = await Promise.all(
        TokenList.data.data.data.map(async (item) => {
          var web3 = new Web3(config.BNBPROVIDER);
          var get_data_tokens = 0;
          if (
            item.tokenSymbol?.toString()?.toLowerCase() !=
            config.currencySymbol?.toString()?.toLocaleLowerCase()
          ) {
            try {
              var tokenObj = new web3.eth.Contract(DETH_ABI, item.tokenAddress);
              get_data_tokens = await tokenObj.methods.decimals().call();
            } catch (e) {
              get_data_tokens = false;
            }
          }
          return {
            label: item.tokenSymbol,
            value: item.tokenSymbol,
            Address: item.tokenAddress,
            Decimal: Number(get_data_tokens),
          };
        })
      );
      dispatch({
        type: Account_Connect,
        Account_Detail: {
          tokenAddress: get_token_details,
        },
      });
    }

    var cover = get_token_details;
    var TokenUSDArray = [];
    if (cover != null || cover != undefined) {
      var newArr = cover.filter((item) => item.label !== config.currencySymbol);
    }
    for (let i = 0; i < newArr?.length; i++) {
      var ans = await WenlamboConvert(newArr[i].label);
      if (ans && ans.data && !isEmpty(ans.data)) {
        TokenUSDArray.push({ USD: ans.data.USD, label: newArr[i].label });
      }
    }
    dispatch({
      type: Account_Connect,
      Account_Detail: {
        Token_convertion: TokenUSDArray,
      },
    });
  }

  async function getServi() {
    var web3sw = new Web3(config.BNBPROVIDER);
    if (web3sw) {
      try {
        var CoursetroContracti = new web3sw.eth.Contract(trade, config.trade);
        var Singlefee1 = await CoursetroContracti.methods
          .getServiceFee()
          .call();
        var buyerFee = Singlefee1[0];
        var sellerFee = Singlefee1[1];
      } catch (e) {
        var buyerFee = 0;
        var sellerFee = 0;
      }
      dispatch({
        type: Account_Connect,
        Account_Detail: {
          Service_Fee_buyer: buyerFee,
          Service_Fee_seller: sellerFee,
        },
      });
      var currency = await convertionValue();

      dispatch({
        type: Account_Connect,
        Account_Detail: {
          currency_convertion: {
            currency: config.currencySymbol,
            USD: currency?.data?.USD,
          },
          SubscribeAmt: localStorage.getItem("wethamt") ?? "0",
        },
      });
    }
  }

  async function AddressUserDetails_GetOrSave_Call(currAddr, bol) {
    var ReqData = {
      addr: String(currAddr).toLowerCase(),
      deviceConnection: bol,
    };
    var Resp = await AddressUserDetails_GetOrSave_Action(ReqData);
    if (Resp && Resp.data && Resp.data.data.token) {
      localStorage.setItem("user_token", Resp.data.data.token);

      return Resp.data.data.RetData.User;
    } else {
      return null;
    }
  }

  const copyText = (a, b) => {
    toast.success("Copied", toasterOption);
  };

  const Disconnect = async () => {
    dispatch({ type: Account_disConnect });

    if (
      localStorage.walletconnect != null &&
      localStorage.walletConnectType == "wc"
    ) {
      await Wallet_Details.providerss.disconnect();
      localStorage.removeItem("walletconnect");
      localStorage.removeItem("walletConnectType");
      AddressUserDetails_GetOrSave_Call(Wallet_Details.UserAccountAddr, false);
    }
    localStorage.clear();

    setTimeout(() => {
      history.push("/");
    }, 1000);
  };

  useEffect(() => {
    getKybkycSubAmt();
  }, [Wallet_Details.UserAccountAddr]);

  useEffect(() => {
    getServi();

    if (
      localStorage.walletConnectType &&
      localStorage.walletConnectType != null &&
      localStorage.walletConnectType == "BinanceWallet"
    ) {
      initialConnectWallet("BinanceWallet");
    }
    if (
      localStorage.walletConnectType &&
      localStorage.walletConnectType != null &&
      localStorage.walletConnectType == "mt"
    ) {
      initialConnectWallet("mt");
    } else if (
      localStorage.walletConnectType == "wc" &&
      localStorage.walletconnect != null
    ) {
      initialConnectWallet("wc");
    }
    getBuyTokensList();
  }, [localStorage.walletConnectType]);

  const getKybkycSubAmt = async () => {
    try {
      if (Wallet_Details?.UserAccountAddr) {
        var getDetails = await getprofile({
          currAddr: Wallet_Details.UserAccountAddr,
        });
        var KycAmount =
          getDetails?.admindata.length > 0 && getDetails?.admindata[0]?.Kyb;
        var KybAmount =
          getDetails?.admindata.length > 0 && getDetails?.admindata[0]?.Kyc;

        dispatch({
          type: Account_Connect,
          Account_Detail: {
            kybAmt: KybAmount,
            kycAmt: KycAmount,
          },
        });
      }
    } catch (err) {}
  };

  async function getWethPrice() {
    try {
      const response = await axios.get(
        "https://api.coingecko.com/api/v3/simple/price?ids=weth&vs_currencies=usd"
      );
      const wethPrice = response.data.weth.usd;
      return wethPrice;
    } catch (error) {
      console.error("Error fetching WETH price:", error);
      return null;
    }
  }

  async function calculateWethAmount(usdAmount) {
    const wethPrice = await getWethPrice();
    if (wethPrice !== null) {
      const wethAmount = usdAmount / wethPrice;
      return wethAmount;
    }
    return null;
  }

  function getTodaySubAmt() {
    const usdAmount = config.subscribeAmount;
    calculateWethAmount(usdAmount)
      .then((wethAmount) => {
        if (wethAmount !== null) {
          localStorage.setItem("wethamt", wethAmount.toFixed(6));

          dispatch({
            type: Account_Connect,
            Account_Detail: {
              SubscribeAmt: wethAmount.toFixed(6),
            },
          });
        } else {
        }
      })
      .catch((err) => {
        calculateWethAmount(usdAmount);
      });
    const today = new Date().toLocaleDateString();
    localStorage.setItem("lastExecutionDate", today);
  }

  const lastExecutionDate = localStorage.getItem("lastExecutionDate");
  const today = new Date().toLocaleDateString();

  if (!lastExecutionDate || lastExecutionDate !== today) {
    getTodaySubAmt();
  }

  const initialConnectWallet = async (type) => {
    var accountDetails = await connectWallet(type, config.networkVersion);

    if (accountDetails.accountAddress != "") {
      var usercall = await getprofile({
        currAddr: accountDetails?.accountAddress,
      });

      var conncheck =
        usercall?.userValue === null
          ? false
          : usercall?.userValue?.deviceConnection;
      setDeciveconn(conncheck);

      if (accountDetails != "") {
        var getorsave = await AddressUserDetails_GetOrSave_Call(
          accountDetails?.accountAddress,
          true
        );
        dispatch({
          type: Account_Connect,
          Account_Detail: {
            UserAccountAddr: accountDetails?.accountAddress,
            providerss: accountDetails?.web3?._provider,
            UserAccountBal: accountDetails?.coinBalance,
            WalletConnected: "true",
            Accounts: accountDetails?.accountAddress,
            AddressUserDetails: getorsave,
            Wen_Bln: accountDetails?.tokenBalance,
          },
        });
      }
    }
  };

  // const connectWallet = async (type, configchainid) => {
  //   var accountDetails = {};
  //   var web3Obj = {};

  //   if (type == "mt" || type == "BinanceWallet" || type == "Coinbase") {
  //     web3Obj = await MetamaskWallet(type, configchainid);
  //   }
  //   if (type == "wc") {
  //     web3Obj = await WalletConnect(type, configchainid);
  //   }
  //   if (web3Obj) {
  //     try {
  //       const accounts = await web3Obj?.eth?.getAccounts();
  //       accountDetails.accountAddress = accounts[0]?.toString()?.toLowerCase();
  //       try {
  //         var coinBalance1 = await web3Obj.eth.getBalance(
  //           accountDetails.accountAddress
  //         );
  //         accountDetails.coinBalance = coinBalance1 / config.decimalvalues;

  //         var conObj = new web3Obj.eth.Contract(
  //           DETH_ABI,
  //           config.tokenAddr[config.tokenSymbol]
  //         );

  //         var tokenBalance1 = await conObj.methods
  //           .balanceOf(accountDetails.accountAddress)
  //           .call();
  //         accountDetails.tokenBalance = (
  //           tokenBalance1 / config.decimalvalues
  //         ).toFixed(config.toFixed);
  //       } catch (err) {}

  //       accountDetails.web3 = web3Obj;
  //       return accountDetails;
  //     } catch (e) {
  //       return accountDetails;
  //     }
  //   }
  // };

  // const MetamaskWallet = async (type, configchainid) => {
  //   let web3;

  //   try {
  //     if (window.ethereum && type === "mt") {
  //       web3 = new Web3(window.ethereum);
  //       if (window.ethereum.isMetaMask) {
  //         try {
  //           const chainId = await web3.eth.net.getId();
  //           if (parseInt(chainId) !== parseInt(configchainid)) {
  //             await chainIdCheck(configchainid, web3);
  //             return web3;
  //           }

  //           await window.ethereum.enable().then(async () => {
  //             const accounts = await web3.eth.getAccounts();
  //             const account = accounts[0]?.toString()?.toLowerCase();

  //             localStorage.setItem("accountInfo", account);
  //             localStorage.setItem("walletConnectType", type);
  //           });
  //         } catch (err) {}
  //       } else {
  //         toast.warn("unistall other etherum wallet and enable metamask ");
  //       }
  //     } else if (window.BinanceChain && type === "BinanceWallet") {
  //       web3 = new Web3(window.BinanceChain);
  //       const chainId = await web3.eth.getChainId();
  //       if (parseInt(chainId) !== parseInt(configchainid)) {
  //         await chainIdCheck(configchainid);
  //         return web3;
  //       }

  //       await window.BinanceChain.request({}).then(async () => {
  //         const accounts = await web3.eth.getAccounts();
  //         const account = accounts[0]?.toString().toLowerCase();
  //         localStorage.setItem("accountInfo", account);
  //         localStorage.setItem("walletConnectType", type);
  //       });
  //     } else if (window.ethereum && type === "Coinbase") {
  //       web3 = new Web3(window.ethereum);
  //       const chainId = await web3.eth.getChainId();

  //       if (parseInt(chainId) !== parseInt(configchainid)) {
  //         await chainIdCheck(configchainid);
  //         return true;
  //       }

  //       await window.ethereum.enable().then(async () => {
  //         const accounts = await web3.eth.getAccounts();
  //         const account = accounts[0]?.toString().toLowerCase();
  //         localStorage.setItem("accountInfo", account);
  //         localStorage.setItem("walletConnectType", type);
  //       });
  //     } else if (window.web3) {
  //       web3 = new Web3(window.web3.currentProvider);
  //       const chainId = await web3.eth.getChainId();
  //       if (parseInt(chainId) !== parseInt(configchainid)) {
  //         await chainIdCheck(configchainid);
  //         return true;
  //       }
  //       const accounts = await web3.eth.getAccounts();
  //       const account = accounts[0]?.toString().toLowerCase();
  //       localStorage.setItem("accountInfo", account);
  //       localStorage.setItem("walletConnectType", type);
  //     } else {
  //     }
  //   } catch (e) {}

  //   return web3;
  // };

  // const chainIdCheck = async (configchainid, web3) => {
  //   try {
  //     const hexString = configchainid?.toString(16);

  //     if (window.ethereum) {
  //       try {
  //         await window.ethereum.request({
  //           method: "wallet_switchEthereumChain",
  //           params: [{ chainId: web3.utils.toHex(configchainid).toString(16) }],
  //         });
  //         return true;
  //       } catch (error) {
  //         if (error.code === 4902) {
  //           try {
  //             await window.ethereum.request({
  //               method: "wallet_addEthereumChain",
  //               params: [
  //                 {
  //                   chainId: "0x" + hexString,
  //                   rpcUrl: config.BNBPROVIDER,
  //                 },
  //               ],
  //             });
  //             return true;
  //           } catch (addError) {
  //             console.error(addError);
  //           }
  //         }
  //         console.error(error);
  //       }
  //     } else {
  //       return false;
  //     }
  //   } catch (err) {}
  // };

  // const WalletConnect = async (type) => {
  //   try {
  //     const CHAIN_ID = config.networkVersion;

  //     if (localStorage.getItem("accountInfo")) {
  //       const provider = await EthereumProvider.init({
  //         projectId: "b8a1daa2dd22335a2fe1d2e139980ae0",
  //         chains: [CHAIN_ID],
  //         showQrModal: true,
  //       });
  //       await provider.enable();
  //       var web3 = new Web3(provider);
  //       const accounts = await provider.request({
  //         method: "eth_requestAccounts",
  //       });
  //       var account = accounts[0]?.toString();
  //       localStorage.setItem("accountInfo", account);
  //       localStorage.setItem("walletConnectType", type);
  //       return web3;
  //     } else {
  //       const provider = await EthereumProvider.init({
  //         projectId: "b8a1daa2dd22335a2fe1d2e139980ae0",
  //         chains: [CHAIN_ID],
  //         showQrModal: true,
  //       });
  //       await provider.connect();

  //       var web3 = new Web3(provider);
  //       const accounts = await provider.request({
  //         method: "eth_requestAccounts",
  //       });
  //       var account = accounts[0]?.toString();
  //       localStorage.setItem("accountInfo", account);
  //       localStorage.setItem("walletConnectType", type);

  //       return web3;
  //     }
  //   } catch (err) {
  //     return err;
  //   }
  // };

  let scrollTop = window.scrollY;
  $(window).scroll(function (event) {
    if (scrollTop > 150) {
      alert(scrollTop);
      $("nav").addClass("scroll");
    } else {
      $("nav").removeClass("scroll");
    }
  });
  var scrollpos = window.scrollY;
  var header = document.getElementById("navbar");

  function add_class_on_scroll() {
    header.classList.add("fade-in");
  }

  function remove_class_on_scroll() {
    header.classList.remove("fade-in");
  }

  window.addEventListener("scroll", function (event) {
    scrollpos = window.scrollY;

    if (scrollpos > 10) {
      add_class_on_scroll();
    } else {
      remove_class_on_scroll();
    }
  });

  const handleMetamaskClick = () => {
    window.$(".modal").modal("hide");

    if (localStorage.walletConnectType != null) {
      localStorage.clear();
    }

    initialConnectWallet("mt");
  };

  window.$("body").scroll(function () {
    var scroll = window.$("body").scrollTop();
    if (scroll >= 50) {
      $("nav").addClass("scroll");
    } else {
      $("nav").removeClass("scroll");
    }
  });
  return (
    <>
      <nav className="navbar" id="navbar">
        <div className="container-fluid custom-container">
          <button
            className={show ? "burger clicked" : "burger"}
            type="button"
            id="burger"
            onClick={() => setShow(!show)}
          >
            <img
              src={`${config.Back_URL}/frontImg/black/burg.svg`}
              alt="burger"
              width={20}
              height={20}
            />
          </button>
          <div className={mobsearch ? "searhnaf navbar_items" : "navbar_items"}>
            <div className="d-flex">
              <Link className="navbar-brand unblocklogonav" to="/">
                <img src={unblokLogo} alt="UNBLOK" className="img-fluid" />
              </Link>
            </div>
            <div className="seathbat">
              <div className="seathbats">
                <div className="searchiconsd mr-2 header_searchiconsd">
                
                  <img
                    src={`${config.Back_URL}/frontImg/black/search.svg`
                    }
                    className="img-fluid"
                    onClick={() => setSearchshow(!searchshow)}
                    alt=""
                  />
                  <i
                    class={
                      searchshow
                        ? "fas fa-search mr-2 ml-2"
                        : "fas fa-times mr-2 ml-2"
                    }
                    onClick={() => setSearchshow(!searchshow)}
                  ></i>
                </div>
                <ul className="homelinkswhole homelinknavae">
                <li className="homelinklist">
                    <a className="homelinklink" href="https://www.unblokapp.com/">
                      App
                    </a>
                  </li>
                  <li className="homelinklist">
                    <NavLink
                      className="homelinklink"
                      aria-current="page"
                      to="/activity"
                    >
                      Activity
                    </NavLink>
                  </li>
                  <li className="homelinklist">
                    <NavLink className="homelinklink" to="/my-account">
                      My Account
                    </NavLink>
                  </li>

                  <li className="homelinklist">
                    <NavLink className="homelinklink" to="/explore/All">
                      Explore
                    </NavLink>
                  </li>
                  <li className="homelinklist">
                    <NavLink className="homelinklink" to="/faq">
                      FAQ
                    </NavLink>
                  </li>
                  <li className="homelinklist">
                    <NavLink className="homelinklink" to="/about">
                      About
                    </NavLink>
                  </li>
                </ul>
                {localStorage.walletConnectType === "" ||
                Wallet_Details.UserAccountAddr === "" ||
                (localStorage.accountInfo === undefined &&
                  localStorage.accountInfo !== "") ? (
                  <p
                    data-bs-toggle="modal"
                    data-bs-target="#connect_wallet"
                    className="mb-0 connectImg"
                  >
                  
                    <img
                      alt=""
                      src={
                        `${config.Back_URL}/frontImg/black/wallet.svg`
                      }
                      className="img-fluid"
                    />
                    <span className="navWalletText">Wallet</span>
                  </p>
                ) : (
                  <div className="connetcwallet">
                    <div class="navbars dropdown connectImg">
                      <img
                        alt=""
                        src={
                          require("../../assets/images/CLIENT/walletblacknew.svg")
                            .default
                        }
                        className="img-fluid disc_wallet"
                      />

                      <div class="dropdown-content">
                        <div className="connectwalletcopy">
                          <h2 className="linkdataa">
                            {Wallet_Details?.UserAccountAddr &&
                              Wallet_Details?.UserAccountAddr != "" &&
                              (Wallet_Details?.UserAccountAddr).substring(
                                0,
                                8
                              ).concat(".....")}
                          </h2>
                          <div className="copyclipath">
                            <CopyToClipboard
                              text={Wallet_Details?.UserAccountAddr}
                              onCopy={() =>
                                copyText(
                                  "invite link",
                                  Wallet_Details?.UserAccountAddr
                                )
                              }
                            >
                              <i className="fas fa-sticky-note notes_fa cur_pointer"></i>
                            </CopyToClipboard>
                          </div>{" "}
                        </div>
                        <div className="polugonbalance">
                          <img src={Polygon} />
                          <div className="polugonbalancename">
                            <h3>Balance</h3>
                            <h2 className="linkdataa">
                              {Wallet_Details?.UserAccountBal}
                            </h2>
                          </div>
                        </div>
                        <div className="polugonbalance pb-3">
                          <img src={Metamaskicon} />
                          <div className="polugonbalancename">
                            <h3>Wallet Connected</h3>
                          </div>
                        </div>
                        <div
                          onClick={Disconnect}
                          className="polugonbalances mt-3"
                        >
                          <p>
                            <button className="connectwallet"> Disconnect </button>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div
            className={show ? "show homelinksmobile" : "homelinksmobile"}
            id="mobilenav"
          >
            <div className="home_links">
              <ul className="homelinkswhole">
                <li className="homelinklist">
                  <a
                    className="homelinklink"
                    href="https://www.unblokapp.com/"
                  >
                    App
                  </a>
                </li>
                <li className="homelinklist">
                  <Link
                    className="homelinklink"
                    aria-current="page"
                    to="/activity"
                  >
                    Activity
                  </Link>
                </li>
                <li className="homelinklist">
                  <Link className="homelinklink" to="/my-account">
                  My Account
                  </Link>
                </li>
                <li className="homelinklist">
                  <Link className="homelinklink" to="/explore/All">
                    Explore
                  </Link>
                </li>
                <li className="homelinklist">
                  <Link className="homelinklink" to="/faq">
                    FAQ
                  </Link>
                </li>
                <li className="homelinklist">
                  <Link className="homelinklink" to="/about">
                    About
                  </Link>
                </li>
              </ul>
              {localStorage.walletConnectType === "" ||
              Wallet_Details.UserAccountAddr === "" ||
              (localStorage.accountInfo === undefined &&
                localStorage.accountInfo !== "") ? (
                <p
                  data-bs-toggle="modal"
                  data-bs-target="#connect_wallet"
                  className="mb-0 connectImg"
                >
                  <img
                    alt=""
                    src={
                      `${config.Back_URL}/frontImg/black/wallet.svg`
                    }
                    className="img-fluid"
                  />
                  <span className="navWalletText">Wallet</span>
                </p>
              ) : (
                <div className="connetcwallet">
                  <div class="navbars dropdown connectImg">
                    <img
                      alt=""
                      src={
                        require("../../assets/images/CLIENT/walletblacknew.svg")
                          .default
                      }
                      className="img-fluid disc_wallet"
                    />

                    <div class="dropdown-content">
                      <div className="connectwalletcopy">
                        <h2 className="linkdataa">
                          {Wallet_Details?.UserAccountAddr &&
                            Wallet_Details?.UserAccountAddr != "" &&
                            (Wallet_Details?.UserAccountAddr).substring(
                              0,
                              8
                            ).concat(".....")}
                        </h2>
                        <div className="copyclipath">
                          <CopyToClipboard
                            text={Wallet_Details?.UserAccountAddr}
                            onCopy={() =>
                              copyText(
                                "invite link",
                                Wallet_Details?.UserAccountAddr
                              )
                            }
                          >
                            <i className="fas fa-sticky-note notes_fa cur_pointer"></i>
                          </CopyToClipboard>
                        </div>{" "}
                      </div>
                      <div className="polugonbalance">
                        <img src={Polygon} />
                        <div className="polugonbalancename">
                          <h3>Balance</h3>
                          <h2 className="linkdataa">
                            {Wallet_Details?.UserAccountBal}
                          </h2>
                        </div>
                      </div>
                      <div className="polugonbalance pb-3">
                        <img src={Metamaskicon} />
                        <div className="polugonbalancename">
                          <h3>Wallet Connected</h3>
                        </div>
                      </div>
                      <div
                        onClick={Disconnect}
                        className="polugonbalances mt-3"
                      >
                        <p>
                          <button className="connectwallet">Disconnect</button>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </nav>

      <div
        class="modal fade"
        id="connect_wallet"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          {
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="staticBackdropLabel">
                  Connect
                  <br />
                  <span> Wallet</span>
                </h5>
              </div>
              <div className="wallet-divider">
                <img src={walletDividerPopup} alt="walet-divider" />
              </div>
              <div class="modal-body">
                <p>Connect your wallet to sign in or create an account</p>

                <h2 className="text-center">
                  Accept{" "}
                  <span>
                    <a href="/terms-of-service">Terms Of Service</a>
                  </span>{" "}
                  and{" "}
                  <span>
                    <a href="/privacy-policy">Privacy Policy</a>
                  </span>
                </h2>

                {
                  <div className="metamasklogo">
                    <img src={Metamask} />
                    <div
                      className="metamasklogoconte"
                      onClick={handleMetamaskClick}
                    >
                      <h3>Metamask</h3>
                      <p>Connect your Metamask Wallet</p>
                    </div>
                  </div>
                }

                <div className="metamasklogo" id="walletConnectlogoo">
                  <img src={Walletconnect} />
                  <div
                    className="metamasklogoconte"
                    onClick={() => {
                      window.$(".modal").modal("hide");
                      initialConnectWallet("wc");
                    }}
                  >
                    <h3>Wallet Connect</h3>
                    <p>Scan with your Mobile Device to Connect</p>
                  </div>
                </div>
                <div className="metamasklogo trust_wallet">
                  <img src={wallet_05} />
                  <div
                    className="metamasklogoconte"
                    onClick={() => {
                      window.$(".modal").modal("hide");
                      initialConnectWallet("mt");
                    }}
                  >
                    <h3>Trust wallet</h3>
                    <p>Scan with your Mobile Device to Connect</p>
                  </div>
                </div>
              </div>
              <div class="modal-footer pb-4">
                <i data-bs-dismiss="modal" class="fa-solid fa-circle-xmark"></i>
              </div>
            </div>
          }
        </div>
      </div>
    </>
  );
}
