import React, { useState,useEffect,useRef } from "react";
import $ from "jquery";
import config from "../../lib/config"

// import Video from "../../assets/images/learn.mp4"
// import Video1 from "../../assets/images/aboutus.ogv"
// import Video2 from "../../assets/images/aboutus.webm"
export default function Videos(){
  $("#headvideo").click(function() {
    $('video')[0].pause();
});
  return(

      <div class="modal fade" id="videolinks" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">Learn More</h5>
            
          </div>
          <div class="modal-body">
           <div class="video-wrapper">
            <video  src={`${config.Back_URL}/frontImg/learn.mp4`} controls 
            poster="Thumbnail.png"
            // disablePictureInPicture 
            controlsList="nodownload" loop playsinline>
      {/* <source src={Video} type="video/mp4" />
      <source src={Video2} type="video/webm" />
      <source src={Video1} type="video/ogg" /> */}

      <source src={`${config.Back_URL}/frontImg/learn.mp4`} type="video/mp4" />
      <source src={`${config.Back_URL}/frontImg/aboutus.webm`} type="video/webm" />
      <source src={`${config.Back_URL}/frontImg/aboutus.ogv`} type="video/ogg" />
    
    </video>
    
            </div>
         
          </div>
          <div class="modal-footer pb-3 pt-1">
            <button type="button" class="connectwallet" data-bs-dismiss="modal" id="headvideo">Cancel</button>
       
          </div>
        </div>
      </div>
    </div>
    )
  }
