import React, {
  forwardRef,
  useImperativeHandle,
  useEffect,
  useState,
} from "react";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Web3 from "web3";
import {
  BidApply_ApproveAction,
  acceptBId_Action,
  CancelBid_Action,
  checkOtherPlatformDetais1155,
  PurchaseNow_Complete_Action_Meta,
} from "../../actions/v1/token";
import { toast } from "react-toastify";
import config from "../../lib/config";
import DETH_ABI from "../../ABI/DETH_ABI.json";
import MULTIPLE from "../../ABI/MULTIPLE.json";
import { useHistory, useLocation } from "react-router-dom";
import Select from "react-select";
import isEmpty from "../../lib/isEmpty";
import trade from "../../ABI/Trade.json";
import { getReceipt } from "../../actions/v1/getReceiptFunc";
import headBottomDivider from "../../assets/images/popupDivider.svg";
import placeYourBid from "../../assets/images/placeYourBidimg.svg";
import closeBidPopup from "../../assets/images/closeBidPopup.svg";
// import plus from "../../assets/images/Markeetplace/plus.svg";
// import negtive from "../../assets/images/Markeetplace/negtive.svg";
import {useCommonContractFunctions,useCommonContractFunctionsApprove} from "../../hooks/useMethods"
import {useContractApprove} from "../../hooks/useContract"
import {useWeb3} from "../../hooks/useWeb3"
import  {useCommomReceipt} from "../../hooks/useReceipts"

toast.configure();
let toasterOption = config.toasterOption;

export const Placeabid = forwardRef((props, ref) => {
  var {
    Set_tokenBidAmt,
    Set_NoOfToken,
    Set_ValidateError,
    Set_YouWillPay,
    Set_YouWillPayFee,
    Set_YouWillGet,
    Set_BidApply_ApproveCallStatus,
    Set_AccepBidSelect,
    item,
    Set_item,
    MyTokenBalance,
    AccepBidSelect,
    tokenBidAmt,
    NoOfToken,
    ValidateError,
    YouWillPay,
    YouWillPayFee,
    YouWillGet,
    BidApply_ApproveCallStatus,
    AllowedQuantity,
    Bids,
  } = props;

  const history = useHistory();
  const [CoinName, setCoinNames] = useState("CAKE");
  const [tokenOwner1, setTokenOwner1] = useState({});
  const [token_owner_detail, set_token_owner_detail] = useState({});
  const [BidformSubmit, Set_BidformSubmit] = React.useState(false);
  const [PurchaseBalance, Set_PurchaseBalance] = React.useState(0);
  const [NewTokenAddress, setNewTokenAddresss] = React.useState("");
  const [NoOfToken_NeedToSend, Set_NoOfToken_NeedToSend] = React.useState(1);
  const [YouWillGetWith, set_YouWillGetWith] = useState(0);
  const [ApprovalCallStatus, setApprovalCallStatus] = React.useState("init");
  const [NewTokenDecimal, setNewTokenDecimal] = React.useState(0);
  const [submitdisable, setsubmitdisable] = useState(true);
  const [MetaMaskAmt, setMetaMaskAmt] = useState(0);
  const [YouWillPayTest, set_YouWillPayTest] = useState(0);
  const [priceoption, setPriceoption] = React.useState([]);
  const [usdcal, setusdCal] = React.useState("");
  const [accept_btn, Set_accept_btn] = React.useState("start");
  const [selectedOption, setSelectedOption] = useState(null);
  const [MintCallStatus, setMintCallStatus] = React.useState("init");
  const [ApproveCallStatus, setApproveCallStatus] = React.useState("init");
  const [buyemail, set_buyemail] = React.useState("");
  const [validemail, set_validemail] = React.useState(false);
  const [invalidemail, set_invalidemail] = React.useState(false);
  const [KeyUserCount, setKeyUserCount] = React.useState(0);
  const [currentOwner, setcurrentOwner] = React.useState(0);
  const [itemPropsData, setItemPropsData] = React.useState({});
  const [enterQuantity, setEnterQuantity] = React.useState(0);

  const Wallet_Details = useSelector((state) => state.wallet_connect_context);
  const customStyles = {
    control: (base, state) => ({
      ...base,
      boxShadow: "none",
    }),
  };
  useEffect(() => {
    // console.log("data===========>", props.itemDataToSend);
    console.log("dvsdfvbdsfbsfdbsdsf",props.itemDataToSend?.item?.CoinName);
    if(props?.itemDataToSend?.item?.CoinName)
{    setCoinNames(props.itemDataToSend?.item?.CoinName);
}
    setItemPropsData(props.itemDataToSend?.item);
  }, [props.itemDataToSend]);
  useEffect(() => {
    cal();
  }, [Wallet_Details.Token_convertion, AccepBidSelect, YouWillPayTest]);
  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];
  useEffect(() => {
    if (CoinName == "") {
      if (
        Wallet_Details.tokenAddress &&
        Wallet_Details.tokenAddress.length > 0
      ) {
        if (tokenOwner1.clocktime && tokenOwner1.endclocktime != null) {
          var bnbs = Wallet_Details.tokenAddress.filter(
            (item) => item.label != config.currencySymbol
          );
        } else {
          var bnbs = Wallet_Details.tokenAddress;
        }
        if (bnbs.length > 0) {
          setPriceoption(bnbs);
        }
      }
    }
  }, [
    Wallet_Details.tokenAddress,
    CoinName,
    Wallet_Details.UserAccountAddr,
    YouWillPayTest,
  ]);

  useEffect(() => {
    Validation_PlaceABid(BidformSubmit);
  }, [
    tokenBidAmt,
    NoOfToken,
    NewTokenDecimal,
    NewTokenAddress,
    YouWillPayTest,
  ]);

  useEffect(() => {
    BalanceCalculation(CoinName);
  }, [
    CoinName,
    Wallet_Details,
    Wallet_Details.UserAccountAddr,
    YouWillPayTest,
    NewTokenDecimal,
    NewTokenAddress,
  ]);

  useEffect(() => {
    PriceCalculate_this({ tokenBidAmt: tokenBidAmt });
  }, [NewTokenDecimal, NewTokenAddress]);

  async function cal() {
    if (
      Wallet_Details.Token_convertion &&
      Wallet_Details.Token_convertion.length > 0
    ) {
      if (
        AccepBidSelect &&
        !isEmpty(AccepBidSelect) &&
        Wallet_Details?.currency_convertion &&
        Wallet_Details?.currency_convertion?.currency
      ) {
        var Pricecal =
          Wallet_Details?.currency_convertion?.currency ===
          AccepBidSelect?.CoinName
            ? (Wallet_Details?.currency_convertion?.USD
                ? Wallet_Details?.currency_convertion?.USD
                : 0) *
              AccepBidSelect?.tokenBidAmt *
              NoOfToken_NeedToSend
            : Wallet_Details?.Token_convertion?.filter(
                (items) => items?.label == AccepBidSelect?.CoinName
              )[0]?.USD *
              AccepBidSelect?.tokenBidAmt *
              NoOfToken_NeedToSend;
        setusdCal(Pricecal);
      }
    }
  }

  const Validation_PlaceABid = (chk) => {
    Set_ValidateError({});
  
    if (chk) {
      var ValidateError = {};
  
      if (CoinName === "") {
        ValidateError.tokenBidAmt = "coinname required";
      }
      if (NoOfToken === "") {
        ValidateError.NoOfToken = '"Quantity" is not allowed to be empty';
      } else if (isNaN(NoOfToken) === true) {
        ValidateError.NoOfToken = '"Quantity" must be a number';
      } else if (NoOfToken === 0) {
        ValidateError.NoOfToken = '"Quantity" is required';
      } else if (NoOfToken > AllowedQuantity) {
        ValidateError.NoOfToken =
          '"Quantity" must be less than or equal to ' + AllowedQuantity;
      }
  
      if (tokenBidAmt === "") {
        ValidateError.tokenBidAmt = '"Bid amount" is not allowed to be empty';
      } else if (isNaN(tokenBidAmt) === true) {
        ValidateError.tokenBidAmt = '"Bid amount" must be a number';
      } else if (tokenBidAmt === 0) {
        ValidateError.tokenBidAmt = '"Bid amount" is required';
      } else if (
        tokenBidAmt <=
        (Bids &&
          Bids?.pending &&
          Bids?.pending?.length > 0 &&
          Bids?.pending[0]?.tokenBidAmt)
      ) {
        ValidateError.tokenBidAmt =
          '"Bid amount" must be greater than ' + Bids.pending[0].tokenBidAmt;
      } else if (item.minimumBid > tokenBidAmt) {
        ValidateError.tokenBidAmt =
          '"Bid amount" must be higher than or equal to ' + item.minimumBid;
      } else if (parseInt(YouWillPay) > parseInt(Wallet_Details.Wen_Bln)) {
        ValidateError.tokenBidAmt =
          "Insufficient balance, Check your wallet balance";
      } else if (Wallet_Details.Wen_Bln === 0) {
        ValidateError.tokenBidAmt =
          "Insufficient balance, Check your wallet balance";
      }
  
      if (buyemail !== "") {
        var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(buyemail)) {
          set_invalidemail("Please enter a valid email address.");
          ValidateError.email = "Please enter a valid email address.";
        }
      } else {
        set_invalidemail("Email is required");
        ValidateError.email = "Email is required";
      }
  
      Set_ValidateError(ValidateError);
  
      // Check if there are any validation errors
      const hasErrors =
        Object.values(ValidateError).filter((error) => error !== "").length > 0;
  
      // If there are errors, set "All Fields Required" text
      if (hasErrors) {
        ValidateError.allFieldsRequired = "All Fields Required";
      }
  
      return ValidateError;
    }
  };
  

  async function FormSubmit_PlaceABid(e) {
    Set_BidformSubmit(true);
    var errors = await Validation_PlaceABid(true);
    var errorsSize = Object.keys(errors).length;

    if (errorsSize !== 0) {
      toast.error(
        "Validation Error",
        toasterOption
      );
      return false;
    } else {
      if (YouWillPay > 0) {
        window.$("#placeabid").modal("hide");
        window.$("#proceed_bid_modal").modal("show");
      } else if (CoinName === "") {
        toast.warn("please Enter all the field");
      }
    }
  }

  // async function BidApply_ApproveCall() {
  //   if (!Wallet_Details.providerss) {
  //     toast.warning("OOPS!... connect Your Wallet", toasterOption);
  //     return false;
  //   }
  //   var web3 = new Web3(Wallet_Details.providerss);
  //   Set_BidApply_ApproveCallStatus("processing");
  //   var CoursetroContract = new web3.eth.Contract(DETH_ABI, NewTokenAddress);
  //   var getAllowance = null;
  //   var ContractCall = null;
  //   var sendVal = null;
  //   var handle = null;
  //   var receipt = null;

  //   try {
  //     getAllowance = await CoursetroContract.methods
  //       .allowance(Wallet_Details.UserAccountAddr, config.trade)
  //       .call();

  //     if (NewTokenDecimal == 18) {
  //       var sendVal = web3.utils.toWei(
  //         String(
  //           Number(web3.utils.fromWei(String(MetaMaskAmt))) +
  //             Number(web3.utils.fromWei(String(getAllowance)))
  //         )
  //       );
  //     } else {
  //       var sendVal =
  //         (Number(MetaMaskAmt) / 10 ** NewTokenDecimal +
  //           Number(getAllowance) / 10 ** NewTokenDecimal) *
  //         10 ** NewTokenDecimal;
  //     }

  //     ContractCall = await CoursetroContract.methods
  //       .approve(
  //         config.trade,
  //         //item.contractAddress,
  //         String(sendVal)
  //       )
  //       .send({ from: Wallet_Details.UserAccountAddr })
  //       .on("transactionHash", async (transactionHash) => {
  //         handle = setInterval(async () => {
  //           receipt = await getReceipt(web3, transactionHash);
  //           clr1();
  //         }, 8000);
  //       });
  //   } catch (e) {
  //     toast.error("Approval Failed", toasterOption);

  //     Set_BidApply_ApproveCallStatus("tryagain");
  //   }
  //   async function clr1() {
  //     if (receipt != null) {
  //       clearInterval(handle);
  //       if (receipt.status === true) {
  //         var BidData = {
  //           tokenCounts: item.tokenCounts,
  //           tokenBidAddress: Wallet_Details.UserAccountAddr,
  //           tokenBidAmt: tokenBidAmt,
  //           NoOfToken: item.type === 721 ? 1 : NoOfToken,
  //           owner: token_owner_detail.tokenOwner,
  //           CoinName: CoinName,
  //           balance: item.type === 721 ? 1 : NoOfToken,
  //           buyemail: buyemail,
  //           tokenName: item.tokenName,
  //         };

  //         var Resp = await BidApply_ApproveAction(BidData);
  //         if (Resp.data && Resp.data.type) {
  //           Set_BidApply_ApproveCallStatus("done");
  //           toast.success("Approval Success", toasterOption);
  //           window.$("#proceed_bid_modal").modal("hide");
  //           setTimeout(() => history.push("/"), 600);
  //         }
  //       }
  //     }
  //   }
  //   // await ContractCall
  // }

  async function PriceCalculate_this(data = {}) {
    if (
      NewTokenDecimal != "" ||
      (NewTokenDecimal != 0 && NewTokenAddress != "") ||
      NewTokenAddress != 0
    ) {
      setsubmitdisable(false);
    }

    var web3 = new Web3(Wallet_Details.providerss);
    var price =
      typeof data.tokenBidAmt != "undefined" ? data.tokenBidAmt : tokenBidAmt;
    var quantity =
      typeof data.NoOfToken != "undefined" ? data.NoOfToken : NoOfToken;
    if (price == "") {
      price = 0;
    }
    if (quantity == "") {
      quantity = 0;
    }

    if (isNaN(price) != true && isNaN(quantity) != true) {
      if (item.type == 721) {
        var totalPrice = price * 1000000;
      } else {
        var totalPrice = price * 1000000 * quantity;
      }
      var toMid = totalPrice;
      var serfee =
        (toMid *
          ((Wallet_Details.Service_Fee_buyer / config.decimalvalues) *
            1000000)) /
        100000000;
      var totfee = serfee + toMid;
      if (NewTokenDecimal == 18) {
        setMetaMaskAmt(web3.utils.toWei(String(Number(totfee) / 1000000)));
      } else {
        setMetaMaskAmt((Number(totfee) / 1000000) * 10 ** NewTokenDecimal);
      }

      Set_YouWillPay(String(Number(totfee) / 1000000));
    } else {
      Set_YouWillPay(0);
    }
  }

  // async function orderApprovecheck(item) {
  //   if (Wallet_Details.providerss == null) {
  //     toast.warning("Connect Wallet", toasterOption);
  //     return false;
  //   }
  //   var web3 = new Web3(Wallet_Details.providerss);
  //   try {
  //     var MultiContract = new web3.eth.Contract(MULTIPLE, item.contractAddress);
  //     var status = await MultiContract.methods
  //       .isApprovedForAll(Wallet_Details.UserAccountAddr, config.trade)
  //       .call();
  //     return status;
  //   } catch (e) {
  //     return false;
  //   }
  // }

  // async function AcceptBid_Proceed() {
  //   var curAddr = Wallet_Details.UserAccountAddr;
  //   if (Wallet_Details.providerss) {
  //     try {
  //       var web3 = new Web3(Wallet_Details.providerss);
  //       var CoursetroContract = null;
  //       var contractCall = null;
  //       var handle = null;
  //       var receipt = null;
  //       var CHcekDethBlnOfBid = new web3.eth.Contract(
  //         DETH_ABI,
  //         NewTokenAddress
  //       );
  //       var getBlnofBidder = await CHcekDethBlnOfBid.methods
  //         .balanceOf(AccepBidSelect.tokenBidAddress)
  //         .call();
  //       var decimals_ = await CHcekDethBlnOfBid.methods.decimals().call();
  //       if (NewTokenDecimal == 18) {
  //         var tokenBalance = getBlnofBidder / config.decimalvalues;
  //       } else {
  //         var tokenBalance = getBlnofBidder / 10 ** (18 - NewTokenDecimal);
  //       }

  //       var passAmt = Number(YouWillPayFee) + Number(YouWillGet);

  //       var getAllowance = 0;

  //       getAllowance = await CHcekDethBlnOfBid.methods
  //         .allowance(AccepBidSelect.tokenBidAddress, config.trade)
  //         .call();
  //       if (Number(NewTokenDecimal) != 18) {
  //         var deci = 18 - Number(NewTokenDecimal);
  //         getAllowance = getAllowance * 10 ** deci;
  //       }

  //       var getAllowancecal = web3.utils.fromWei(String(getAllowance));

  //       var priceamt = web3.utils.toWei(String(passAmt));
  //       if (tokenBalance > passAmt) {
  //         if (
  //           Number(getAllowancecal) >= passAmt
  //           // true
  //         ) {
  //           if (NoOfToken_NeedToSend) {
  //             try {
  //               var ids = [
  //                 item.tokenCounts,
  //                 web3.utils.toWei(String(passAmt)),
  //                 NoOfToken_NeedToSend,
  //                 item.type,
  //                 item.tokenCounts,
  //               ];

  //               Set_accept_btn("process");

  //               CoursetroContract = new web3.eth.Contract(trade, config.trade);

  //               contractCall = await CoursetroContract.methods
  //                 .acceptBId(
  //                   String(AccepBidSelect.CoinName),
  //                   AccepBidSelect.tokenBidAddress,
  //                   ids
  //                 )
  //                 .send({
  //                   from: String(Wallet_Details.UserAccountAddr),
  //                 })
  //                 .on("transactionHash", async (transactionHash) => {
  //                   handle = setInterval(async () => {
  //                     receipt = await getReceipt(web3, transactionHash);
  //                     clr1();
  //                   }, 8000);
  //                 });
  //             } catch (e) {
  //               toast.error("Bid Cancelled", toasterOption);
  //               Set_accept_btn("try");
  //             }
  //             async function clr1() {
  //               if (receipt !== null) {
  //                 clearInterval(handle);
  //                 if (receipt.status === true) {
  //                   if (
  //                     String(item.contractAddress).toLowerCase() !==
  //                     String(config.multipleContract).toLowerCase()
  //                   ) {
  //                     var postMetaData = {
  //                       tokenOwner: Wallet_Details.UserAccountAddr,
  //                       tokenCounts: item.tokenCounts,
  //                       NoOfToken: item.type === 721 ? 1 : NoOfToken,
  //                       contractAddress: item.contractAddress,
  //                     };
  //                     var resultt = await PurchaseNow_Complete_Action_Meta(
  //                       postMetaData
  //                     );
  //                   }
  //                   var acceptBId_Payload = {
  //                     tokenCounts: item.tokenCounts,
  //                     NoOfToken: NoOfToken_NeedToSend,
  //                     tokenBidAddress: AccepBidSelect.tokenBidAddress,
  //                     UserAccountAddr_byaccepter:
  //                       Wallet_Details.UserAccountAddr,
  //                     transactionHash: receipt.transactionHash,
  //                     owner: token_owner_detail.tokenOwner,
  //                     SubscribeEnd: new Date(),
  //                     tokenName: item.tokenName,
  //                     currentOwnerDet: currentOwner,
  //                   };
  //                   var Resp = await acceptBId_Action(acceptBId_Payload);
  //                   Set_accept_btn("done");
  //                   window.$(".modal").modal("hide");
  //                   setTimeout(() => history.push("/"), 600);
  //                 }
  //               }
  //             }
  //           }
  //         } else {
  //           toast.error(
  //             "Insufficient Allowance"
  //           );
  //           Set_accept_btn("error1");
  //         }
  //       } else {
  //         toast.error(
  //           "Insufficient Balance"
  //         );
  //         Set_accept_btn("error");
  //       }
  //     } catch (e) {}
  //   }
  // }





  async function orderApprovecheck(item) {
    if (Wallet_Details.providerss == null) {
      toast.warning("Connect Wallet", toasterOption);
      return false;
    }
    var web3 = new Web3(Wallet_Details.providerss);
    try {
      var MultiContract = new web3.eth.Contract(MULTIPLE, item.contractAddress);
      var status = await MultiContract.methods
        .isApprovedForAll(Wallet_Details.UserAccountAddr, config.trade)
        .call();
      return status;
    } catch (e) {
      return false;
    }
  }

//   async function AcceptBid_Proceed() {
//     console.log("accepppppt");
//     var curAddr = Wallet_Details.UserAccountAddr;
//     if (Wallet_Details.providerss) {
//       try {
//         var web3 = new Web3(Wallet_Details.providerss);
//         var CoursetroContract = null;
//         var contractCall = null;
//         var handle = null;
//         var receipt = null;
//         var CHcekDethBlnOfBid = new web3.eth.Contract( DETH_ABI, NewTokenAddress );
//         var getBlnofBidder = await CHcekDethBlnOfBid.methods
//           .balanceOf(AccepBidSelect.tokenBidAddress)
//           .call();
//         var decimals_ = await CHcekDethBlnOfBid.methods.decimals().call();
//         if (NewTokenDecimal == 18) {
//           var tokenBalance = getBlnofBidder / config.decimalvalues;
//         } else {
//           var tokenBalance = getBlnofBidder / 10 ** (18 - NewTokenDecimal);
//         }

//         var passAmt = Number(YouWillPayFee) + Number(YouWillGet);
        
//         var getAllowance = 0;

//         getAllowance = await CHcekDethBlnOfBid.methods
//           .allowance(AccepBidSelect.tokenBidAddress, config.trade)
//           .call();
//         if (Number(NewTokenDecimal) != 18) {
//           var deci = 18 - Number(NewTokenDecimal);
//           getAllowance = getAllowance * 10 ** deci;
//         }

     
//         var getAllowancecal = web3.utils.fromWei(String(getAllowance));

      
//         var priceamt = web3.utils.toWei(String(passAmt));
//         if (tokenBalance > passAmt) {
//           if (
//             Number(getAllowancecal) >= passAmt
//             // true
//           ) {
//             if (NoOfToken_NeedToSend) {
//               try {
//                 var ids = [
//                   item.tokenCounts,
//                   web3.utils.toWei(String(passAmt)),
//                   NoOfToken_NeedToSend,
//                   item.type,
//                   item.tokenCounts,
//                 ];


//                 CoursetroContract = new web3.eth.Contract(trade, config.trade);


//                 const Transcation = {
//                   from: Wallet_Details.UserAccountAddr,
//                   // to: Provider.networkConfiguration.TradeContract,
//                   // data: encoded,
//                   value: passAmt
//               };
//                 var encoded =  await CoursetroContract.methods.acceptBId(
//                   String(AccepBidSelect.CoinName),
//                   AccepBidSelect.tokenBidAddress,
//                   ids
//                 )
//                 var gasprice = await web3.eth.getGasPrice();
//                 var gaslimit = await encoded.estimateGas(Transcation)
//                 var gasLimit = web3.utils.toHex(gaslimit);
//                 var gasPrice = web3.utils.toHex(gasprice);

// console.log("dfvdfvdfvdfv",gasPrice,gasLimit);

//                 Set_accept_btn("process");
              

//                 contractCall = await CoursetroContract.methods
//                   .acceptBId(
//                     String(AccepBidSelect.CoinName),
//                     AccepBidSelect.tokenBidAddress,
//                     ids
//                   )
//                   .send({
//                     from: String(Wallet_Details.UserAccountAddr),
//                     gasLimit:gasLimit,
//                     gasPrice:gasPrice
//                   })
//                   .on("transactionHash", async (transactionHash) => {
//                     handle = setInterval(async () => {
//                       receipt = await getReceipt(web3, transactionHash);
//                       clr1();
//                     }, 8000);
//                   });
//               } catch (e) {
//                 console.log("errrrrrrrrrrrrrrrrrr",e);
//                 toast.error("accept bid cancelled", toasterOption);
//                 Set_accept_btn("try");
//               }
//               async function clr1() {
//                 if (receipt !== null) {
//                   clearInterval(handle);
//                   if (receipt.status === true) {
         
//                     var acceptBId_Payload = {
//                       tokenCounts: item.tokenCounts,
//                       NoOfToken: NoOfToken_NeedToSend, 
//                       tokenBidAddress: AccepBidSelect.tokenBidAddress,
//                       UserAccountAddr_byaccepter: Wallet_Details.UserAccountAddr,
//                       transactionHash: receipt.transactionHash,
//                       owner: token_owner_detail.tokenOwner,
//                       SubscribeEnd: new Date(),
//                       tokenName: item.tokenName,
//                       currentOwnerDet:currentOwner
//                     };
//                     var Resp = await acceptBId_Action(acceptBId_Payload);
//                     Set_accept_btn("done");
//                     window.$(".modal").modal("hide")
//                     setTimeout(() =>history.push("/")
//                     , 600);
//                   }
//                 }
//               }
//             }
//           } else {
//             toast.error(
//               "This Bidder Doesn't have enough allowance,Pleae try  another bidder"
//             );
//             Set_accept_btn("error1");
//           }
//         } else {
//           toast.error(
//             "This Bidder Doesn't have enough balance,Pleae try  another bidder"
//           );
//           Set_accept_btn("error");
//         }
//       } catch (e) {
//         console.log("eeeeeeeeeeeeeeeee",e);
//       }
//     }
//   }

// async function BidApply_ApproveCall() {
//   if (!Wallet_Details.providerss) {
//     toast.warning("OOPS!... connect Your Wallet", toasterOption);
//     return false;
//   }
//   var web3 = new Web3(Wallet_Details.providerss);
//   Set_BidApply_ApproveCallStatus("processing");
//   console.log("vsdfvssdfvsfv",NewTokenAddress);

//   var CoursetroContract = new web3.eth.Contract(DETH_ABI, NewTokenAddress);
//   var getAllowance = null;
//   var ContractCall = null;
//   var sendVal = null;
//   var handle = null;
//   var receipt = null;

//   try {
//     getAllowance = await CoursetroContract.methods
//       .allowance(Wallet_Details.UserAccountAddr, config.trade)
//       .call();
    
//     if (NewTokenDecimal == 18) {
//       var sendVal = web3.utils.toWei(
//         String(
//           Number(web3.utils.fromWei(String(MetaMaskAmt))) +
//             Number(web3.utils.fromWei(String(getAllowance)))
//         )
//       );
//     } else {
//       var sendVal =
//         (Number(MetaMaskAmt) / 10 ** NewTokenDecimal +
//           Number(getAllowance) / 10 ** NewTokenDecimal) *
//         10 ** NewTokenDecimal;
//     }
    



//     const Transcation = {
//       from: Wallet_Details.UserAccountAddr,
//       // to: Provider.networkConfiguration.TradeContract,
//       // data: encoded,
//      // value: Arg.PriceWithSerfee
//   };
//     var encoded =  await CoursetroContract.methods.approve(config.trade, String(sendVal))
//     var gasprice = await web3.eth.getGasPrice();
//     var gaslimit = await encoded.estimateGas(Transcation)
//     var gasLimit = web3.utils.toHex(gaslimit);
//     var gasPrice = web3.utils.toHex(gasprice);
//     ContractCall = await CoursetroContract.methods
//       .approve(
//         config.trade,
//         //item.contractAddress,
//         String(sendVal)
//       )
//       .send({ from: Wallet_Details.UserAccountAddr,gasLimit:gasLimit,gasPrice:gasPrice })
//       .on("transactionHash", async (transactionHash) => {
//         handle = setInterval(async () => {
//           receipt = await getReceipt(web3, transactionHash);
//           clr1();
//         }, 8000);
//       });
//   } catch (e) {

//     console.log("zvsdvsdfsdfvsdf",e);
//     toast.error("Approve failed", toasterOption);
    
//     Set_BidApply_ApproveCallStatus("tryagain");
//   }
//   async function clr1() {
    
//     if (receipt != null) {
//       clearInterval(handle);
//       if (receipt.status === true) {
//         var BidData = {
//           tokenCounts: item.tokenCounts,
//           tokenBidAddress: Wallet_Details.UserAccountAddr,
//           tokenBidAmt: tokenBidAmt,
//           NoOfToken: item.type === 721 ? 1 : NoOfToken,
//           owner: token_owner_detail.tokenOwner,
//           CoinName: CoinName,
//           balance: item.type === 721 ? 1 : NoOfToken,
//           buyemail: buyemail,
//           tokenName: item.tokenName,
//         };
        
//         var Resp = await BidApply_ApproveAction(BidData);
//         if (Resp.data && Resp.data.type) {
//           Set_BidApply_ApproveCallStatus("done");
//           toast.success("Approve Successfully", toasterOption);
//           window.$("#proceed_bid_modal").modal("hide")            
//           setTimeout(() => history.push("/"), 600);

//         }
//       }
//     }
//   }
//   // await ContractCall
// }





async function AcceptBid_Proceed() {

      var web3 = await useWeb3()
      var handle = null;
      var CHcekDethBlnOfBid = useContractApprove( DETH_ABI, NewTokenAddress );
  
      var passAmt = Number(YouWillPayFee) + Number(YouWillGet);
 
      var ids = [ item.tokenCounts, web3.utils.toWei(String(passAmt)), NoOfToken_NeedToSend, item.type, item.tokenCounts, ];

      Set_accept_btn("process");
    
        const ContractCall = await useCommonContractFunctions('acceptBId',[String(AccepBidSelect.CoinName),AccepBidSelect.tokenBidAddress,ids],Wallet_Details,passAmt)
        console.log("ContractCall..................",ContractCall);
         const receipt = await useCommomReceipt(ContractCall)
         console.log("receipt..................",receipt);
         if(receipt)
         {
          clr1(receipt)
         }

      async function clr1(receipt) {
        if (receipt !== null) {
 
            var acceptBId_Payload = {
              tokenCounts: item.tokenCounts,
              NoOfToken: NoOfToken_NeedToSend, 
              tokenBidAddress: AccepBidSelect.tokenBidAddress,
              UserAccountAddr_byaccepter: Wallet_Details.UserAccountAddr,
              transactionHash: receipt.transactionHash,
              owner: token_owner_detail.tokenOwner,
              SubscribeEnd: new Date(),
              tokenName: item.tokenName,
              currentOwnerDet:currentOwner
            };
            var Resp = await acceptBId_Action(acceptBId_Payload);
            Set_accept_btn("done");
            window.$(".modal").modal("hide")
            setTimeout(() =>history.push("/")
            , 600);
          
        }
      }
 
}



async function AcceptBid_Proceed1()
{
  var web3 = await useWeb3()
  var CHcekDethBlnOfBid =await useContractApprove( DETH_ABI, NewTokenAddress );
  console.log("CHcekDethBlnOfBid",CHcekDethBlnOfBid);
  var getBlnofBidder = await CHcekDethBlnOfBid.methods.balanceOf(AccepBidSelect.tokenBidAddress).call();
  console.log("dfvsdfvsdfvsdf",AccepBidSelect.tokenBidAddress, config.trade);
  var getAllowance = await CHcekDethBlnOfBid.methods.allowance(AccepBidSelect.tokenBidAddress, config.trade).call();
  console.log("sdsfsdddddd",getAllowance);

  var getAllowancecal = web3.utils.fromWei(String(getAllowance));
  if (Number(NewTokenDecimal) != 18) {
    var deci = 18 - Number(NewTokenDecimal);
    getAllowance = getAllowance * 10 ** deci;
  }
  var passAmt = Number(YouWillPayFee) + Number(YouWillGet);
  if (NewTokenDecimal == 18) { 
    var tokenBalance = getBlnofBidder / config.decimalvalues; 
  } 
  else { 
    var tokenBalance = getBlnofBidder / 10 ** (18 - NewTokenDecimal);
   }
console.log("dfvsdfvsdfvsdfvsdfvsdf",tokenBalance < passAmt ||  Number(getAllowancecal) >= passAmt,tokenBalance ,passAmt ,  Number(getAllowancecal) ,passAmt);
  if (tokenBalance < passAmt ||  Number(getAllowancecal) <= passAmt) {
    if ( Number(getAllowancecal) <= passAmt ) 
    {
      Set_accept_btn("error1");

     return toast.error(
        "This Bidder Doesn't have enough allowance,Pleae try another bidder"
      );
      
    } 
   else {
    Set_accept_btn("error");

   return toast.error( "This Bidder Doesn't have enough balance,Pleae try  another bidder" );
  }
}
else{
  AcceptBid_Proceed()
}
}








async function BidApply_ApproveCall() {

  // try {

    var web3 = await useWeb3()
    console.log("web3..................",web3);
    Set_BidApply_ApproveCallStatus("processing");
    console.log("DETH_ABI, NewTokenAddress..................",DETH_ABI, NewTokenAddress);

    var CoursetroContract =await useContractApprove(DETH_ABI, NewTokenAddress);
    var sendVal = null;  
    console.log("CoursetroContract..................",CoursetroContract);

    var getAllowance = await CoursetroContract.methods.allowance(Wallet_Details.UserAccountAddr, config.trade) .call();
    console.log("getAllowance..................",getAllowance);

    if (NewTokenDecimal == 18) {
      var sendVal = web3.utils.toWei( String( Number(web3.utils.fromWei(String(MetaMaskAmt))) + Number(web3.utils.fromWei(String(getAllowance))) ) );
    } else {
      var sendVal = (Number(MetaMaskAmt) / 10 ** NewTokenDecimal + Number(getAllowance) / 10 ** NewTokenDecimal) * 10 ** NewTokenDecimal;
    }
    console.log("sendVal..................",sendVal);

    const ContractCall = await useCommonContractFunctionsApprove('approve',[config.trade,String(sendVal)],Wallet_Details,"",DETH_ABI, NewTokenAddress)
    console.log("ContractCall..................",ContractCall);
     const receipt = await useCommomReceipt(ContractCall)
     console.log("receipt..................",receipt);
     if(receipt)
     {
      clr1(receipt)
     }

  // } catch (e) {

  //   console.log("error in bid approve",e);
  //   toast.error("Approve failed", toasterOption);
  //   Set_BidApply_ApproveCallStatus("tryagain");
  // }
  async function clr1(receipt) {
    
    // if (receipt != null) {
    //   // clearInterval(handle);
    //   if (receipt.status === true) {
        var BidData = {
          tokenCounts: item.tokenCounts,
          tokenBidAddress: Wallet_Details.UserAccountAddr,
          tokenBidAmt: tokenBidAmt,
          NoOfToken: item.type === 721 ? 1 : NoOfToken,
          owner: token_owner_detail.tokenOwner,
          CoinName: CoinName,
          balance: item.type === 721 ? 1 : NoOfToken,
          buyemail: buyemail,
          tokenName: item.tokenName,
        };
        
        var Resp = await BidApply_ApproveAction(BidData);
        if (Resp.data && Resp.data.type) {
          Set_BidApply_ApproveCallStatus("done");
          toast.success("Approve Successfully", toasterOption);
          window.$("#proceed_bid_modal").modal("hide")            
          setTimeout(() => history.push("/"), 600);

        }
    //   // }
    // }
  }
}

  useImperativeHandle(ref, () => ({
    async PlaceABid_Click(
      item,
      tokenowners_current,
      itemCur,
      Bids,
      WalletUserDetails,
      CurrentKeyUser
    ) {
      setKeyUserCount(CurrentKeyUser);
      set_buyemail(WalletUserDetails?.email);

      if (itemCur.minimumBid > 0&&itemCur.CoinName) {
        setCoinNames(itemCur.CoinName);
      }
      setTokenOwner1(tokenowners_current);
      Set_item(item);
      set_token_owner_detail(itemCur);

      if (Wallet_Details.UserAccountAddr !== "") {
        Set_BidformSubmit(false);
        if (Bids && Bids.myBid && Bids.myBid.tokenBidAmt) {
          Set_tokenBidAmt(Bids.myBid.tokenBidAmt);
          Set_NoOfToken(Bids.myBid.NoOfToken);
        }

        window.$("#placeabid").modal("show");
      } else {
        window.$("#placeabid").modal("hide");
        // window.$("#placeabid").modal("show");
        window.$("#connect_modal").modal("show");
        toast.warning("Connect Wallet");
      }
    },
    async PriceCalculate(data = {}) {
      PriceCalculate_this(data);
    },
    async AcceptBid_Select(item, curBid_val) {
      setTokenOwner1(curBid_val);
      setcurrentOwner(
        item.tokenowners_current.length > 0 ? item.tokenowners_current[0] : []
      );

      if (Wallet_Details.providerss) {
        var web3 = new Web3(Wallet_Details.providerss);
        var BuyOwnerDetail = {
          tokenOwner: Wallet_Details.UserAccountAddr,
          tokenCounts: curBid_val.tokenCounts,
          contractAddress: item.contractAddress,
        };

        var tokenAdd = Wallet_Details.tokenAddress.filter(
          (item) => item.label === curBid_val.CoinName
        );

        setNewTokenAddresss(tokenAdd[0]?.Address);
        var balance = await checkOtherPlatformDetais1155(
          item,
          BuyOwnerDetail,
          item.type,
          web3
        );

        var currOwner =
          item.tokenowners_current.length > 0 &&
          item.tokenowners_current.filter(
            (item) => item.tokenOwner === Wallet_Details.UserAccountAddr
          );
        if (currOwner[0]?.balance > Number(balance)) {
          toast.warning("Purchase Limit Exceeded", toasterOption);
          setTimeout(() => {
            history.push("/");
          }, 1000);
          return false;
        } else {
          if (curBid_val && curBid_val.tokenBidAmt) {
            Set_AccepBidSelect(curBid_val);
            setCoinNames(curBid_val.CoinName);
            var totalAmt = 0;
            if (Number(MyTokenBalance) < Number(curBid_val.pending)) {
              Set_NoOfToken_NeedToSend(MyTokenBalance);
              totalAmt =
                Number(MyTokenBalance) *
                (Number(curBid_val.tokenBidAmt) * 1000000);
            } else {
              Set_NoOfToken_NeedToSend(curBid_val.pending);
              totalAmt =
                Number(curBid_val.pending) *
                (Number(curBid_val.tokenBidAmt) * 1000000);
            }
            var toMid = Number(totalAmt);
            var ServiceFee_val =
              (toMid *
                ((Wallet_Details.Service_Fee_seller / config.decimalvalues) *
                  1000000)) /
              100000000;
            var YouWillGet_Val = toMid - ServiceFee_val;
            Set_YouWillPayFee(ServiceFee_val / 1e6);
            Set_YouWillGet(YouWillGet_Val / 1e6);
            var royalFee = 0;
            var YouWillGet_Val1 =
              (toMid - (ServiceFee_val + royalFee)) / 1000000;
            set_YouWillGetWith(YouWillGet_Val1);
            var check = await orderApprovecheck(item);

            if (check) {
              setApprovalCallStatus("done");
              window.$("#bidnow").modal("show");
            } else {
              window.$("#accept_price_item_modal").modal("show");
            }
          }
        }
      } else {
        toast.warning("Connect Wallet");

        window.$("#connect_modal").modal("show");
      }
    },
    async CancelBid_Select(curBid_val) {
      if (Wallet_Details.providerss) {
        if (
          curBid_val &&
          curBid_val.pending > 0 &&
          (curBid_val.status === "pending" ||
            curBid_val.status === "partiallyCompleted")
        ) {
          Set_AccepBidSelect(curBid_val);
          window.$("#cancel_model").modal("show");
        } else {
          window.$(".modal").modal("hide");
        }
      } else {
        window.$("#connect_modal").modal("show");
      }
    },
  }));

  // const priceoptionfunc = (e) => {
  //   setCoinNames(e.label);
  // };
  useEffect(()=>{
    Set_NoOfToken(enterQuantity);
    PriceCalculate_this({ NoOfToken: enterQuantity });
  },[enterQuantity,Set_NoOfToken,PriceCalculate_this])
  const enterQuantityPlus = () => {
    setEnterQuantity(enterQuantity + 1);

  };
  const enterQuantityNigtive = () => {
    if (enterQuantity > 0) {
      setEnterQuantity(enterQuantity - 1);
    }
  };

  const inputChange = (e) => {
    if (
      e &&
      e.target &&
      typeof e.target.value != "undefined" &&
      e.target.name
    ) {
      var value = e.target.value;
      switch (e.target.name) {
        case "tokenBidAmt":
          Set_tokenBidAmt(value);
          PriceCalculate_this({ tokenBidAmt: value });
          break;
        case "tokenBidQuantity":
          if (e.target.id === "tokenBidQuantity") {
            Set_NoOfToken(value);
            PriceCalculate_this({ NoOfToken: value });
          } else {
            Set_NoOfToken(enterQuantity);
            PriceCalculate_this({ NoOfToken: enterQuantity });
          }
          break;
        case "buyemail":
          set_buyemail(e.target.value);
          break;
      }
    }
  };

  async function BalanceCalculation(Coin) {
    console.log("asdvsdvsd",Coin,Wallet_Details.providerss,trade, config.trade);
    if (Wallet_Details.providerss) {
      try {
        var web3 = new Web3(Wallet_Details.providerss);
        if (web3) {
          var web3 = new Web3(Wallet_Details.providerss);
          const MultiContract = new web3.eth.Contract(trade, config.trade);

          var tokenAddress = await MultiContract.methods.getTokenAddress(Coin) .call();
console.log("vsdfvsdfvsfvsf",tokenAddress);
          const TokenObj = new web3.eth.Contract(DETH_ABI, tokenAddress);

          var TokenBalance = Number( await TokenObj.methods .balanceOf(Wallet_Details.UserAccountAddr) .call() );
          var TokenDecimal = Number(await TokenObj.methods.decimals().call());
          Set_PurchaseBalance(TokenBalance / 10 ** TokenDecimal);
          setNewTokenDecimal(TokenDecimal);
          setNewTokenAddresss(tokenAddress);
        }
      } catch (e) {
        console.log("advsdfvsdfvsdfvsdf",e);

        return false;
      }
    }
  }

  async function ApproveCall() {
    if (Wallet_Details.providerss == null) {
      var receiptt = null;
      var handlee = null;
      toast.warning("Connect Wallet", toasterOption);
      return false;
    }
    var web3 = new Web3(Wallet_Details.providerss);
    var currAddr = Wallet_Details.UserAccountAddr;

    if (currAddr === "") {
      toast.warning("Connect Wallet", toasterOption);
      return false;
    }
    setApprovalCallStatus("processing");
    try {
      var MultiContract = new web3.eth.Contract(
        MULTIPLE,
        props.item.contractAddress
      );
      await MultiContract.methods
        .setApprovalForAll(config.trade, true)
        .send({
          from: Wallet_Details.UserAccountAddr,
        })
        .on("transactionHash", async (transactionHash) => {
          if (transactionHash !== "") {
            handlee = setInterval(async () => {
              receiptt = await getReceipt(web3, transactionHash);
              clr();
            }, 8000);
          }
        });
    } catch (error) {
      toast.error("Approval Failed", toasterOption);
      setApprovalCallStatus("tryagain");
    }
    async function clr() {
      if (receiptt != null) {
        clearInterval(handlee);
        toast.success("Approval Success", toasterOption);
        setApprovalCallStatus("done");
      }
    }
  }

  async function priceListFunction() {
    window.$("#accept_price_item_modal").modal("hide");
    window.$("#bidnow").modal("show");
  }

  async function CancelBid_Proceed(curBid_val) {
    var payload = {
      tokenCounts: curBid_val.tokenCounts,
      tokenBidAddress: curBid_val.tokenBidAddress,
      owner: token_owner_detail.tokenOwner,
    };
    var Resp = await CancelBid_Action(payload);
    if (
      Resp &&
      Resp.data &&
      Resp.data.toast &&
      Resp.data.toast.type &&
      Resp.data.toast.message
    ) {
      if (Resp.data.toast.type === "error") {
        toast.error(Resp.data.toast.message, toasterOption);
      } else if (Resp.data.toast.type === "success") {
        toast.success(Resp.data.toast.message, toasterOption);
      }
      window.$(".modal").modal("hide");

      setTimeout(() => {
        history.push("/");
      }, 600);
    } else {
      history.push("/");
    }
  }

  return (
    <>
      <div
        className="modal fade"
        id="placeabid"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="placeabidlabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="placeabidlabel">
                Place your <span id="yellowBidText">Bid</span>
              </h5>
            </div>
            <div className="headDivider">
              <img src={headBottomDivider} alt="headBottomDivider" />
            </div>
            <div className="ImagsContentWrapper">
              <div className="idImagWrapper">
                <img
                  id="imagePlaceBid"
                  src={
                    itemPropsData?.additionalImage !== ""
                      ? `${config.Back_URL}/nftImg/${itemPropsData?.tokenCreator}/${itemPropsData?.additionalImage}`
                      : `${config.IPFS_IMG}/${itemPropsData.ipfsimage}`
                  }
                  alt="place your bid"
                />
              </div>
              <div className="idTextWrapper">
                <h5>{itemPropsData && itemPropsData?.tokenName}</h5>
                <h6>
                  Total Supply{" "}
                  {itemPropsData &&
                    itemPropsData?.kycinfo &&
                    itemPropsData?.kycinfo?.tokenowners_current[0]?.balance}
                </h6>
              </div>
            </div>
            <div className="modal-body">
              {/* <h1 className="placeabid_title">Enter Bid </h1> */}
              <div className="text-center">
                <div className="palcebideths">
                  <input
                    type="text"
                    maxLength={config.maxLength}
                    name="tokenBidAmt"
                    id="tokenBidAmt"
                    placeholder="Enter Bid Amount"
                    aria-label="bid"
                    autoComplete="off"
                    value={tokenBidAmt === 0 ? undefined : tokenBidAmt}
                    onChange={inputChange}
                  />
                  {/* <Select
                    defaultValue={selectedOption}
                    name="CoinName"
                    styles={customStyles}
                    classNamePrefix="react-select"
                    onChange={priceoptionfunc}
                    options={priceoption.filter(
                      (item) => item.label !== config.currencySymbol
                    )}
                    isSearchable={false}
                  /> */}
                </div>
                {ValidateError?.tokenBidAmt && (
                  <span className="text-danger">
                    {ValidateError?.tokenBidAmt}
                  </span>
                )}
              </div>
              <div className="mb-3 input_grp_style_1">
                {/* <p className="email_label_pop email_popkr">Enter quantity</p> */}
                {/* <input
                  type="text"
                  class="form-control palcebideths palcebideths_krdrop"
                  name="tokenBidQuantity"
                  id="tokenBidQuantity"
                  value={NoOfToken}
                  onChange={inputChange}
                  placeholder="Enter Quantity"
                  autocomplete="off"
                /> */}
                <div
                  class="form-control palcebideths palcebideths_krdrop"
                  id="Enter_Quantity"
                >
                  <div>
                    <h2 className="">Enter Quantity</h2>
                  </div>
                  <div className="item_added">
                    <img
                      src={`${config.Back_URL}/frontImg/Markeetplace/plus.svg`
                    }
                      alt=""
                      onClick={enterQuantityPlus}
                    />
                    {enterQuantity}
                    <img
                      src={`${config.Back_URL}/frontImg/Markeetplace/negtive.svg`
                    }
                      alt=""
                      onClick={enterQuantityNigtive}
                    />
                  </div>
                </div>
                {ValidateError.NoOfToken && (
                  <span className="text-danger">
                    {ValidateError?.NoOfToken}
                  </span>
                )}
              </div>

              <div className="mb-3 input_grp_style_1">
                {/* <p className="email_label_pop email_popkr">
                Enter Email Address
                </p> */}
                <input
                  type="text"
                  class="form-control palcebideths palcebideths_krdrop"
                  name="buyemail"
                  id="buyemail"
                  value={buyemail}
                  onChange={inputChange}
                  placeholder="Enter Email Address"
                  autocomplete="off"
                />
                {ValidateError.email && (
                  <span className="text-danger">{ValidateError.email}</span>
                )}
              </div>
              <div className="placeabidbalance">
                <h6 className="bidnowconent">Total Daily Active User</h6>
                <h5>100,000</h5>
              </div>
              {/* <div className="placeabidbalance">
                <h6 className="bidnowconent" id="WalletBalance">
                  Wallet Balance{" "}
                </h6>
                <h5 id="WalletBalance">
                  {PurchaseBalance} {CoinName}
                </h5>
              </div> */}
              <div className="placeabidbalance">
                <h6 className="bidnowconent">Service Fee</h6>
                <h5>
                  {(Number(Wallet_Details.Service_Fee_buyer) + Number(0)) /
                    config.decimalvalues /
                    100}

                  {CoinName}
                  <span></span>
                </h5>
              </div>

              <div className="placeabidbalance">
                <h6 className="bidnowconent">Fee per Daily Active User</h6>
                <h5>0.00000000001 {CoinName}</h5>
              </div>
              <div className="placeabidbalance">
                <h6 className="bidnowconent">Transaction Total</h6>
                <h5>{YouWillPay + " " + CoinName}</h5>
              </div>
            </div>

            {
              <div className="modal-footer">
                <button
                  className="buybtn"
                  onClick={() => FormSubmit_PlaceABid()}
                >
                  Bid
                </button>
                <button className="bidbtn" data-bs-dismiss="modal">
                  <img src={closeBidPopup} />
                </button>
              </div>
            }
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="proceed_bid_modal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                Follow Steps
              </h5>
            </div>
            <div className="modal-body">
              <div className="media approve_media">
                <i
                  className="fas fa-check mr-3 pro_complete"
                  aria-hidden="true"
                ></i>
                <div className="media-body">
                  <p className="mt-0 approve_text">Approve</p>
                </div>
              </div>
            </div>
            <div className="modal-footer ">
              <button
                className={"buybtn buybtn_cap"}
                disabled={
                  BidApply_ApproveCallStatus === "processing" ||
                  BidApply_ApproveCallStatus === "done"
                }
                onClick={BidApply_ApproveCall}
              >
                {BidApply_ApproveCallStatus === "processing" && (
                  <i
                    className="fa fa-spinner mr-3 spinner_icon"
                    aria-hidden="true"
                    id="circle1"
                  ></i>
                )}
                {BidApply_ApproveCallStatus === "init" && "Place a bid"}
                {BidApply_ApproveCallStatus === "processing" &&
                  "In-progress..."}
                {BidApply_ApproveCallStatus === "done" && "Done"}
                {BidApply_ApproveCallStatus === "tryagain" && "Try Again"}
              </button>
              <div className="closePopupWrapper" data-bs-dismiss="modal">
                <img src={closeBidPopup} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="bidnow"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="bidnowLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="bidnowLabel">
                Accept Bid
              </h5>
            </div>
            <div className="modal-body p-4">
              <h6 className="bidnowconent">
                You are about to accept the bid for{" "}
              </h6>
              <h5 className="blksedition">{item.tokenName}</h5>
              <h1>
                {AccepBidSelect?.tokenBidAddress?.slice(0, 10)?.concat("...")}
              </h1>
              <h1></h1>
              <p>
                {AccepBidSelect.tokenBidAmt}
                {CoinName} for {NoOfToken_NeedToSend}
              </p>
              <div className="text-center"></div>

              <div className="buynow_popup mb-2  mt-5">
                <div className="buynow_popupname">
                  <h6>Service Fee </h6>
                </div>
                <div className="buynow_popupname1">
                  <h6 className="percentagepop">
                    {((Number(Wallet_Details.Service_Fee_buyer) /
                      Number(config.decimalvalues)) *
                      Number(AccepBidSelect.tokenBidAmt)) /
                      100}
                    {config.currencySymbol}
                  </h6>
                </div>
              </div>
              <div className="buynow_popup mb-2">
                <div className="buynow_popupname">
                  <h6>Royalty Fee in %</h6>
                </div>
                <div className="buynow_popupname1">
                  <h6 className="percentagepop">{item.tokenRoyality} %</h6>
                </div>
              </div>
              <div className="buynow_popup mb-2">
                <div className="buynow_popupname">
                  <h6>You will get</h6>
                </div>
                <div className="buynow_popupname1">
                  <h6 className="percentagepop">
                    {YouWillGetWith}
                    {CoinName}
                  </h6>
                </div>
              </div>
            </div>

            <div className="modal-footer">
              {NewTokenDecimal != 0 && (
                <a
                  className="buybtn"
                  onClick={
                    (accept_btn === "start" || accept_btn === "try") &&
                    AcceptBid_Proceed1
                  }
                  disabled={accept_btn === "process" || accept_btn === "done"}
                >
                  {" "}
                  {accept_btn === "start" && "Accept Bid"}
                  {accept_btn === "try" && "Try Again"}
                  {accept_btn === "process" && "In-Progress"}
                  {accept_btn === "wait" && "wait"}
                  {accept_btn === "done" && "Done"}
                  {accept_btn === "error" && "Something went wrong"}
                  {accept_btn === "error1" &&
                    "Bidder doesn't have enough allowance"}
                </a>
              )}
              <a
                className="bidbtn"
                data-bs-dismiss="modal"
                disabled={accept_btn === "process"}
              >
                <img src={closeBidPopup} />
                Cancel
              </a>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="accept_price_item_modal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="bidnowLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="accept_price_item_modallabel">
                Accept Bid
              </h5>
            </div>
            <div className="modal-body p-4">
              <form>
                <div className="media approve_media">
                  {ApprovalCallStatus === "init" && (
                    <i
                      className="fas fa-check mr-3 pro_initial"
                      aria-hidden="true"
                    ></i>
                  )}
                  {ApprovalCallStatus === "processing" && (
                    <i
                      className="fa fa-spinner mr-3 spinner_icon"
                      aria-hidden="true"
                    ></i>
                  )}
                  {ApprovalCallStatus === "done" && (
                    <i
                      className="fas fa-check mr-3 pro_complete"
                      aria-hidden="true"
                    ></i>
                  )}
                  {ApprovalCallStatus === "tryagain" && (
                    <i
                      className="fa fa-spinner mr-3 spinner_icon"
                      aria-hidden="true"
                    ></i>
                  )}

                  <div className="media_body">
                    <p className="mt-0 approve_text">Approve</p>
                  </div>
                </div>
                <div className="text-center">
                  <button
                    className="connectwallet w-100"
                    disabled={
                      ApprovalCallStatus === "processing" ||
                      ApprovalCallStatus === "done"
                    }
                    onClick={ApproveCall}
                  >
                    {" "}
                    {ApprovalCallStatus === "processing" && (
                      <i
                        className="fa fa-spinner mr-3 spinner_icon"
                        aria-hidden="true"
                        id="circle1"
                      ></i>
                    )}
                    {ApprovalCallStatus === "init" && "Approve"}
                    {ApprovalCallStatus === "processing" && "In-progress..."}
                    {ApprovalCallStatus === "done" && "Done"}
                    {ApprovalCallStatus === "tryagain" && "Try Again"}
                  </button>
                </div>
                <div className="media approve_media">
                  {MintCallStatus === "init" && (
                    <i
                      className="fas fa-check mr-3 pro_initial"
                      aria-hidden="true"
                    ></i>
                  )}
                  {MintCallStatus === "processing" && (
                    <i
                      className="fa fa-spinner mr-3 spinner_icon"
                      aria-hidden="true"
                    ></i>
                  )}
                  {MintCallStatus === "done" && (
                    <i
                      className="fas fa-check mr-3 pro_complete"
                      aria-hidden="true"
                    ></i>
                  )}
                  {MintCallStatus === "tryagain" && (
                    <i
                      className="fa fa-spinner mr-3 spinner_icon"
                      aria-hidden="true"
                    ></i>
                  )}
                  <div className="media-body">
                    <p className="mt-0 approve_text">Accept the Bid</p>
                  </div>
                </div>
                <div className="text-center my-3">
                  <button
                    className="connectwallet w-100"
                    disabled={
                      ApprovalCallStatus !== "done" ||
                      MintCallStatus === "processing" ||
                      MintCallStatus === "done"
                    }
                    onClick={priceListFunction}
                  >
                    {MintCallStatus === "processing" && (
                      <i
                        className="fa fa-spinner mr-3 spinner_icon"
                        aria-hidden="true"
                        id="circle1"
                      ></i>
                    )}
                    {MintCallStatus === "init" && "Start"}
                    {MintCallStatus === "processing" && "In-progress..."}
                    {MintCallStatus === "done" && "Done"}
                    {MintCallStatus === "tryagain" && "Try Again"}
                  </button>
                </div>
              </form>
            </div>

            <div className="modal-footer">
              <Link className="bidbtn" data-bs-dismiss="modal">
                <img src={closeBidPopup} />
                Cancel
              </Link>
              </div>
            </div>
          </div>
        </div>
    

      <div
        className="modal fade"
        id="cancel_model"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                Cancel Order
              </h5>
            </div>

            <div className="modal-body">
              <h6 className="bidnowconent">You are about to cancel bid for</h6>

              <h3 className="ownernane text-center">{item.tokenName}</h3>
              <p className="info_title text-center">
                {AccepBidSelect.tokenBidAmt}
                {CoinName}
              </p>
            </div>
            <div className="modal-footer">
              <button
                class="buybtn"
                onClick={() => CancelBid_Proceed(AccepBidSelect)}
              >
                cancel bid
              </button>
              <button
                class="bidbtn"
                data-bs-dismiss="modal"
                onClick={() => {
                  Set_tokenBidAmt(0);
                  Set_NoOfToken(0);
                }}
              >
                <img src={closeBidPopup} />
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});
