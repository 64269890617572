import Web3 from "web3";
import DETH_ABI from "../ABI/DETH_ABI.json";
import { AES, enc } from 'crypto-js'
import { EthereumProvider } from '@walletconnect/ethereum-provider';
import Configsfrom from "../lib/config"
import config from "../lib/config"
import { toast } from "react-toastify";


export const connectWallet = async (type, configchainid) => {
  var accountDetails = {};
  var web3Obj = {};

  if (type == "mt") {
    web3Obj = await MetamaskWallet(type, configchainid);
  }
  if (type == "wc") {
    web3Obj = await WalletConnect(type, configchainid);
  }
  if (web3Obj) {
    try {
      const accounts = await web3Obj?.eth?.getAccounts();
      accountDetails.accountAddress = accounts[0]?.toString()?.toLowerCase();
      try {
        var coinBalance1 = await web3Obj.eth.getBalance(
          accountDetails.accountAddress
        );
        accountDetails.coinBalance = coinBalance1 / config.decimalvalues;

        var conObj = new web3Obj.eth.Contract(
          DETH_ABI,
          config.tokenAddr[config.tokenSymbol]
        );

        var tokenBalance1 = await conObj.methods
          .balanceOf(accountDetails.accountAddress)
          .call();
        accountDetails.tokenBalance = (
          tokenBalance1 / config.decimalvalues
        ).toFixed(config.toFixed);
      } catch (err) {}

      accountDetails.web3 = web3Obj;
      return accountDetails;
    } catch (e) {
      return accountDetails;
    }
  }
};

export const MetamaskWallet = async (type, configchainid) => {
  let web3;

  try {
    if (window.ethereum && type === "mt") {
      web3 = new Web3(window.ethereum);
      if (window.ethereum.isMetaMask) {
        try {
          const chainId = await web3.eth.net.getId();
          if (parseInt(chainId) !== parseInt(configchainid)) {
            await chainIdCheck(configchainid, web3);
            return web3;
          }

          await window.ethereum.enable().then(async () => {
            const accounts = await web3.eth.getAccounts();
            const account = accounts[0]?.toString()?.toLowerCase();

            localStorage.setItem("accountInfo", account);
            localStorage.setItem("walletConnectType", type);
          });
        } catch (err) {}
      } else {
        toast.warn("unistall other etherum wallet and enable metamask ");
      }
    }
    else if (window.web3) {
      web3 = new Web3(window.web3.currentProvider);
      const chainId = await web3.eth.getChainId();
      if (parseInt(chainId) !== parseInt(configchainid)) {
        await chainIdCheck(configchainid);
        return true;
      }
      const accounts = await web3.eth.getAccounts();
      const account = accounts[0]?.toString().toLowerCase();
      localStorage.setItem("accountInfo", account);
      localStorage.setItem("walletConnectType", type);
    } 
  } catch (e) {}

  return web3;
};

export const chainIdCheck = async (configchainid, web3) => {
  try {
    const hexString = configchainid?.toString(16);

    if (window.ethereum) {
      try {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: web3.utils.toHex(configchainid).toString(16) }],
        });
        return true;
      } catch (error) {
        if (error.code === 4902) {
          try {
            await window.ethereum.request({
              method: "wallet_addEthereumChain",
              params: [
                {
                  chainId: "0x" + hexString,
                  rpcUrl: config.BNBPROVIDER,
                },
              ],
            });
            return true;
          } catch (addError) {
            console.error(addError);
          }
        }
        console.error(error);
      }
    } else {
      return false;
    }
  } catch (err) {}
};

export const WalletConnect = async (type) => {
  try {
    const CHAIN_ID = config.networkVersion;

    if (localStorage.getItem("accountInfo")) {
      const provider = await EthereumProvider.init({
        projectId: "b8a1daa2dd22335a2fe1d2e139980ae0",
        chains: [CHAIN_ID],
        showQrModal: true,
      });
      await provider.enable();
      var web3 = new Web3(provider);
      const accounts = await provider.request({
        method: "eth_requestAccounts",
      });
      var account = accounts[0]?.toString();
      localStorage.setItem("accountInfo", account);
      localStorage.setItem("walletConnectType", type);
      return web3;
    } else {
      const provider = await EthereumProvider.init({
        projectId: "b8a1daa2dd22335a2fe1d2e139980ae0",
        chains: [CHAIN_ID],
        showQrModal: true,
      });
      await provider.connect();

      var web3 = new Web3(provider);
      const accounts = await provider.request({
        method: "eth_requestAccounts",
      });
      var account = accounts[0]?.toString();
      localStorage.setItem("accountInfo", account);
      localStorage.setItem("walletConnectType", type);

      return web3;
    }
  } catch (err) {
    return err;
  }
};


export const encodedata = (data) => {
  console.log("ahsdhasidasdasd", Configsfrom.encodekey);
  let encrpteddata = AES.encrypt(JSON.stringify(data), Configsfrom.encodekey).toString()
  return encrpteddata;

}

export const decodedata = (data) => {
  let dataaaa = AES.decrypt(data, Configsfrom.encodekey);
  console.log("JSON.parse(dataaaa.toString(enc.Utf8))",dataaaa, JSON.parse(dataaaa.toString(enc.Utf8)));
  return JSON.parse(dataaaa.toString(enc.Utf8))

};