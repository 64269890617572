import React, { useEffect, useState } from "react";
import Header from "../components/Header/Head.js";
import Footer from "../components/Footer/Footer.js";
import Yahoo from "../assets/images/CLIENT/YAHOO.svg";
import Benzinga from "../assets/images/CLIENT/BENZINGA.svg";
import AP from "../assets/images/CLIENT/AP.svg";
import Morningstar from "../assets/images/CLIENT/MORNINGSTAR.svg";
import Asiaone from "../assets/images/CLIENT/ASIAONE.svg";
import Fox from "../assets/images/CLIENT/FOX.svg";
import Loader from "./Loader.js";
import Marquee from "react-fast-marquee";
// import Profile from "../assets/images/CLIENT/about.webp";
// import cartoonImg from "../assets/images/About/background.svg";
// import cartoonImage from "../assets/images/About/backgroundImage.svg";
// import World from "../assets/images/About/World.svg";
// import Cryptocurrency from "../assets/images/About/Cryptocurrency.svg";
// import Benefits from "../assets/images/About/Benefits.svg";
// import Global from "../assets/images/About/Global.svg";
// import getStart from "../assets/images/About/about_getstart.svg";
// import itemDevider from "../assets/images/Markeetplace/my_account_devider.svg";
// import about_star from "../assets/images/About/star.svg";
// import about_light_star from "../assets/images/About/light_star.svg";
// import about_rectangle from "../assets/images/About/rectangle.svg";
// import about_poligon from "../assets/images/About/polygon.svg";
// import about_content from "../assets/images/About/about_content.svg";
import "./explore.css";
import "./about.css";

// import cartoonImg from "../assets/images/About/backgound.svg";
// import cartoonImage from "../assets/images/About/backgroundImage.svg";
// import World from "../assets/images/About/World.svg";
// import Cryptocurrency from "../assets/images/About/Cryptocurrency.svg";
// import Benefits from "../assets/images/About/Benefits.svg";
// import Global from "../assets/images/About/Global.svg";
// import getStart from "../assets/images/About/about_getstart.svg";
// import itemDevider from "../assets/images/Markeetplace/my_account_devider.svg";
// import about_star from "../assets/images/About/star.svg";
// import about_light_star from "../assets/images/About/light_star.svg";
// import about_rectangle from "../assets/images/About/rectangle.svg";
// import about_poligon from "../assets/images/About/polygon.svg";
// import about_content from "../assets/images/About/about_content.svg";
import "./explore.css";
import "./about.css";
import config from "../lib/config"


export default function About() {
  const [imageUrl, setImageUrl] = useState(`${config.Back_URL}/frontImg/About/backgound.svg`);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1441) {
        setImageUrl(`${config.Back_URL}/frontImg/About/backgroundImage.svg`
        );
      } else {
        setImageUrl(`${config.Back_URL}/frontImg/About/backgound.svg`);
      }
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [loading, Set_loading] = React.useState(true);
  const [img, Set_img] = React.useState(
    require("../assets/images/Thumbnail.jpg")
  );
  const [vid, Set_vid] = React.useState(`${config.Back_URL}/frontImg/about.mp4`);

  useEffect(() => {
    if (img && vid) {
      Set_loading(false);
    }
  }, []);

  return loading ? (
    <Loader />
  ) : (
    <>
      <div className="aboutus faq otherpage">
        <Header />
        <div className="aboutus_body">
          <div className="container container_about">
            <div className="about_title">
              <div
                style={{
                  display: "flex",
                  alignItems: "start",
                }}
              >
                <div className="title_content_about">
                  <h1
                    style={{
                      color: "#FFF600",
                      textShadow: "none",
                    }}
                  >
                    About
                  </h1>
                  <h1>UNBLOK</h1>
                </div>
              </div>
              <p>Ignite Your Marketing Efforts with Data-Driven NFTs</p>
            </div>
            <p className="header_p">
              UNBLOK gives your business unparalleled access to customer data
              directly from their phone. You get more precise and comprehensive
              data than ever before, including location, keyword usage, and
              online shopping habits. We have data that’s not available anywhere
              else. Not even Facebook or Google have this much insight – and you
              can access all this actionable data for FREE with our innovative
              new NFTs
            </p>
          </div>
          <div className="header_image">
            <img src={imageUrl} alt="" />
          </div>
        </div>
        <div className="aboutbanner_bgcolor">
          <div className="container custom-container">
            <div className="about_banner_content mt-5" id="about_page4Wrap">
              <div className="about_baner_heading">
                <img src={`${config.Back_URL}/frontImg/About/World.svg`
} id="about_baner_heading" alt="" />
                <div className="about_baner_heading_title">
                  <h2 className="about_title">World</h2>
                  <h2
                    className="about_title_outline"
                    style={{
                      color: "#fff",
                      textShadow:
                        "-1px 1px 0 #000,1px 1px 0 #000,1px -1px 0 #000,-1px -1px 0 #000",
                      marginTop: "-22px",
                    }}
                  >
                    Insights
                  </h2>
                </div>
              </div>
              <div
                className="mb-5 "
                style={{
                  maxWidth: "scenes",
                  overflow: "hidden",
                }}
              >
                <img src={`${config.Back_URL}/frontImg/Markeetplace/my_account_devider.svg`
} alt="itemDevider" />
              </div>
              <div className="about_peragraph">
                <p className="strong">
                  Unlike other NFTs that give you ownership of images or
                  objects, UNBLOK NFTs contain user data mined directly from
                  their phones using our patent-pending keyboard.
                </p>
                <p className="normal">
                  Users willingly trade their data in exchange for crypto
                  rewards – so you don’t have to worry about privacy violations
                  and limitations from Facebook, Google, and other advertising
                  platforms.{" "}
                </p>

                <p className="normal">
                  With our powerful NFTs, you can delve into the deepest corners
                  of your customers' online activities: extracting keywords,
                  understanding their preferences, and pinpointing their
                  locations. This invaluable information allows you to refine
                  your marketing strategies, target prospects with precision,
                  and optimize your offerings to better meet their needs.
                </p>

                <p className="normal">
                  If your marketing costs have been skyrocketing due to new
                  privacy tools and policy changes, UNBLOK can give you the
                  precise marketing advantage you need to succeed.{" "}
                </p>
              </div>
              <div className="about_baner_heading position-relative" >
              <img
                    className="img4 position-absolute"
                    src={`${config.Back_URL}/frontImg/About/about_content.svg`
                  }
                    alt="explore_star"
                  />
                <img src={`${config.Back_URL}/frontImg/About/Cryptocurrency.svg`
} alt="" id="about_baner_heading" />
                <div className="about_baner_heading_title mt-3">
                  <h2 className="about_title Cryptocurrency">Cryptocurrency</h2>
                  <h2
                    className="about_title_outline Cryptocurrency"
                    style={{
                      color: "#fff",
                      textShadow:
                        "-1px 1px 0 #000,1px 1px 0 #000,1px -1px 0 #000,-1px -1px 0 #000",
                    }}
                  >
                    Collecting
                  </h2>
                </div>
              </div>
              <div
                className="mb-5 "
                style={{
                  maxWidth: "scenes",
                  overflow: "hidden",
                }}
              >
                <img src={`${config.Back_URL}/frontImg/Markeetplace/my_account_devider.svg`
} alt="itemDevider" />
              </div>
              <div className="about_peragraph">
                <p className="strong">
                  UNBLOK doesn’t just improve marketing for businesses. We also
                  give consumers unprecedented control over how their data is
                  used – and reward them with crypto payments.
                </p>

                <p className="normal">
                  By participating in our platform and exchanging their data for
                  cryptocurrency, customers become active participants in the
                  digital economy. They gain control over their own data and
                  have the opportunity to monetize it, receiving tangible assets
                  for their valuable information.{" "}
                </p>

                <p className="normal">
                  With UNBLOK, customers are no longer passive data providers;
                  they become valued partners in shaping the digital landscape.
                </p>

                <p className="normal">
                  This also means that consumers are no longer driven to
                  side-step data collection. As ad blockers and privacy tools
                  become more prevalent, UNBLOK will keep an open line of
                  communication between you and your customers.{" "}
                </p>
              </div>
              <div className="about_baner_heading mt-5">
                <img src={`${config.Back_URL}/frontImg/About/Benefits.svg`
} alt="" id="about_benefit_heading" />
                <div className="about_baner_heading_title">
                  <h2 className="about_title Benefits">Benefits for</h2>
                  <h2
                    className="about_title_outline Benefits"
                    style={{
                      color: "#fff",
                      textShadow:
                        "-1px 1px 0 #000,1px 1px 0 #000,1px -1px 0 #000,-1px -1px 0 #000",
                    }}
                  >
                    Both Parties
                  </h2>
                </div>
              </div>
              <div
                className="mb-5"
                style={{
                  maxWidth: "scenes",
                  overflow: "hidden",
                }}
              >
                <img src={`${config.Back_URL}/frontImg/Markeetplace/my_account_devider.svg`
} alt="itemDevider" />
              </div>
              <div className="about_peragraph">
                <p className="strong">
                  Traditional digital marketing involves taking user data
                  without their consent, which creates animosity, distrust, and
                  conflict: users naturally want to prevent companies from
                  getting useful data that is essential to their growth and
                  survival online.
                </p>
                <p className="normal">
                  UNBLOK creates a system that benefits everyone, all while
                  being 100% transparent.{" "}
                </p>

                <p className="normal">
                  Our NFTs collect user data directly from their phones'
                  keyboards, all while paying customers with cryptocurrency
                  through our app. This means your business gains an
                  unprecedented behind-the-scenes look at your customers while
                  they are incentivized for their participation.
                </p>
              </div>

              <div className="about_baner_heading mt-5 position-relative">
              <img
                    className="img5 position-absolute"
                    id="about_light_star"
                    src={`${config.Back_URL}/frontImg/About/light_star.svg`
                  }
                    alt="explore_star"
                  />
                <img src={`${config.Back_URL}/frontImg/About/Global.svg`
} alt="" id="about_global_heading" />
                <div className="about_baner_heading_title">
                  <h2 className="about_title Global">
                    Global Insights that Go Beyond
                  </h2>
                  <h2
                    className="about_title_outline Global"
                    style={{
                      color: "#fff",
                      textShadow:
                        "-1px 1px 0 #000,1px 1px 0 #000,1px -1px 0 #000,-1px -1px 0 #000",
                    }}
                  >
                    Individual Websites
                  </h2>
                </div>
              </div>
              <div
                className="mb-5"
                style={{
                  maxWidth: "scenes",
                  overflow: "hidden",
                }}
              >
                <img src={`${config.Back_URL}/frontImg/Markeetplace/my_account_devider.svg`
} alt="itemDevider" />
              </div>
              <div className="about_peragraph">
                <p className="strong">
                  When you run ads on Google, Facebook, or TikTok, your ability
                  to target users is limited: Facebook only knows what people do
                  when they’re on Facebook.{" "}
                </p>

                <p className="normal">
                  But UNBLOK collects data directly from customers’s keyboards,
                  across dozens of apps and websites.
                  <br />
                  This means you get insights that even the world’s most
                  powerful advertising engines don’t have.
                </p>
              </div>
            </div>
          </div>
          <div className="getstart_section position-relative">
            <div className="marque_main_box">
              <div className="marque_about">
                <div className="marque_interdiv">
                  <Marquee
                    speed={100}
                    gradientWidth={50}
                    autoFill={true}
                    gradient={true}
                    gradientColor="#FFF600"
                  >
                    <img
                      src={Yahoo}
                      className="img-fluid1"
                      height="55"
                      alt=""
                    />
                    {/* <img
                      src={Benzinga}
                      className="img-fluid1"
                      height="25"
                      alt=""
                    /> */}
                    {/* <img src={AP} className="img-fluid1" height="55" alt="" /> */}
                    <img
                      src={Morningstar}
                      className="img-fluid1"
                      height="35"
                      alt=""
                    />
                    <img
                      src={Asiaone}
                      className="img-fluid1"
                      height="30"
                      alt=""
                    />
                    <img src={Fox} className="img-fluid1 fox_image" alt="" />
                  </Marquee>
                </div>
              </div>
            </div>
            <div
              className="container position-relative"
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
                <div className="about_image_box position-absolute">
                  <img
                    className="img1 position-absolute"
                    src={`${config.Back_URL}/frontImg/About/star.svg`
                  }
                    alt="explore_star"
                  />
                  <img
                    className="img2 position-absolute"
                    src={`${config.Back_URL}/frontImg/About/polygon.svg`
                  }
                    alt="explore_star"
                  />
                </div>
              <div className="get_start_box ">
                <div className="getstatr_button">
                  <img src={`${config.Back_URL}/frontImg/About/about_getstart.svg`
} alt="" />

                  <button type="button" class="btn btn_getstart">
                    Get Started Now
                  </button>
                </div>
                <div className="getstatr_content">
                  <p className="about_getstart">
                    Join us on this groundbreaking journey as we reshape the way
                    businesses harness the power of customer data.{" "}
                  </p>

                  <p className="about_getstart">
                    UNBLOK gives you a competitive edge and transforms the way
                    you market and provide services to your customers. Be the
                    first to use this new kind of customer data and grow your
                    business before your competitors discover the best-kept
                    secret in digital marketing.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}
