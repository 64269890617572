import Web3 from "web3";
import config from '../lib/config'

export const useWeb3 = async () => {

  var provider = window?.ethereum ? window?.ethereum : config.BNBPROVIDER;
  const web3 = new Web3(provider);
  return web3;


  // console.log("khdsfkjfdkdfkfsd", Wallet_Details);
  // if (localStorage.walletConnectType == 'wc' && localStorage.walletconnect != null) {
  //   var provider = config.provider
  //   const web3 = new Web3(provider);
  //   return Wallet_Details;
  // }
  // else if (localStorage.walletConnectType && localStorage.walletConnectType != null && localStorage.walletConnectType == 'mt') {
  //   var provider = window?.ethereum ? window?.ethereum : config.BNBPROVIDER;
  //   const web3 = new Web3(provider);
  //   return web3;

  // }

}


