import Web3 from "web3";
import TradeAbi from '../ABI/Trade.json'
// import { useWeb3 } from "./useWeb3";
import config from '../lib/config'

export const useContract = async () => {
    const web3 = await useWeb3()
    try {
        console.log("useContract First call");
       
        console.log("Eweb3 Calls", web3);
            const contract = new web3.eth.Contract(TradeAbi,config.multipleContract);
            console.log("contract After Intraction", contract);
            return contract;
       
    } catch (e) {
        console.log("Catch error Contact error", e);
    }
}


export const useContractApprove = async (Abi,address) => {
    const web3 = await useWeb3()
    try {
        console.log("useContract First call");
       
        console.log("Eweb3 Calls", web3);
            const contract = new web3.eth.Contract(Abi,address);
            console.log("contract After Intraction", contract);
            return contract;
       
    } catch (e) {
        console.log("Catch error Contact error", e);
    }
}

export const useWeb3 = async () => {


    var provider = window?.ethereum ? window?.ethereum : config.BNBPROVIDER;
    const web3 = new Web3(provider);
    return web3;

    // console.log("khdsfkjfdkdfkfsd", Wallet_Details);
    // if (localStorage.walletConnectType == 'wc' && localStorage.walletconnect != null) {
    //   var provider = config.provider
    //   const web3 = new Web3(provider);
    //   return Wallet_Details;
    // }
    // else if (localStorage.walletConnectType && localStorage.walletConnectType != null && localStorage.walletConnectType == 'mt') {
    //   var provider = window?.ethereum ? window?.ethereum : config.BNBPROVIDER;
    //   const web3 = new Web3(provider);
    //   return web3;
  
    // }
  
  }