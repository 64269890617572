
import { useWeb3 } from "./useWeb3";


export const useCommomReceipt = async (Hash) => {
    const web3 = await useWeb3()
    try {
        // const Hash = await useAcceptBidTransfer(Arg, Type, Provider)
        const receipt = await web3.eth.getTransactionReceipt(Hash);
        return receipt;
    }
    catch (error) {
        // console.log("useAuctionReceipt-Hookerror",error)
        return false
    }
}


